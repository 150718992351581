<template>
  <qk_form use_type="frpassport_base" :field="field" :req="req" title="法国护照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: '10CV23144 不填写自动生成', },
        { k: 'state', title: '出生地', placeholder: '如BOURGOIN JALLIEU', },
        { k: 'ads2', title: '省名(注意大小写)', placeholder: '如la Savole CHAMBERY ', },
        { k: 'ads3', title: '地址', placeholder: '如:CHEMIN DES OMBRES|VILLEMARTIN|73350 BOZEL 用|分割 不要加|FRANCE', many : 1},

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state', 'ads2', 'ads3'
      ],


    }
  },
  methods: {
  },



};
</script>
