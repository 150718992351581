<template>
  <qk_form use_type="italy_dl" :diy_head = "true" :has_back="true" :select_head="true"   :field="field" :req="req" title="意大利-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '编号', placeholder: 'TO873239E 不填写自动生成', },
        { k: 'ads1', title: '地名', placeholder: '如:PADERNO FRANCIACORTA ', },
        

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor',  'state', 'ads1'
      ],


    }
  },
  methods: {
  },



};
</script>
