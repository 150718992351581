<template>
  <div class="hello">
    <el-dialog :title="info.tit" :visible.sync="show">
      <div v-if="info.key">
          <el-button type="primary" @click="gomaip"> {{ $lang(43426)}} </el-button>
          <el-button type="primary" @click="bing" v-if="info.latlng_url"> {{ $lang(50060)}} </el-button>
          <br>
          <div class="map">
            <iframe :src="map" frameborder="0" class="map2"></iframe>
          </div>


      </div>
      <div v-else>
        {{ $lang(69658)}}
      </div>
      <div v-html="txt">

      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
       info : {
        id : 0,
        type : 0,
        tit : '',
        latlng_url : '',
       },
       txt : '',
       map : '',
      show: false,
    }
  },
  props: {

    id: {
      type: Number,
      required: false,
      default: 0,
    }
  },
  methods : {
    gomaip(){
      window.open(`https://www.google.com/maps?q=${this.info.lat},${this.info.lng}`, '_blank');
    },
    bing() {
      window.open(this.info.latlng_url, '_blank');
    },
    f5 (id) {
      // alert(this.id)
      this.get("/train/point_info", { id: id }).then((e) => {
        this.info = e.info
        this.txt = ''
        this.map = e.map
        if (this.info.type == 0) {
          window.open(this.info.url, '_blank');
        } else {
          this.show = true
          this.txt = this.info[this.cache('lang_config')]
        }



      });
    },
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 400px;
  border: 1px solid #000;
  overflow: hidden;
}
.map2 {
width: 110%;
  height: 400px;
}
</style>
