<template>
  <qk_form use_type="belg_id" :field="field" :req="req" title="比利时ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: 'ID号', placeholder: '593-7716621-71 不填写自动生成', },

        { k: 'state', title: '地址', placeholder: '如:Brecht', },


      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state',
      ],


    }
  },
  methods: {
  },



};
</script>
