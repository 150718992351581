<template>
  <qk_form use_type="singapore_dl" :field="field"  :has_back="true"  :req="req" title="新加坡-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
       { k: 'ssn', title: '编号', placeholder: 'S9025671U 不填写自动生成', },
      ],
      line: '',
      req: [
        'fn', 'ln', 'bor',
      ],


    }
  },
  methods: {
  },



};
</script>
