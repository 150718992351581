<template>
  <div class="login">
    <div class="t1">

    </div>
    <div class="t2">
      <div class="rtttt">
        {{$lang(50127)}}
      </div>
      <div v-if="step == 1">


        <div class="inp1">
          <span> {{ $lang(42618)}} </span>
          <el-input class="inp2" v-model="put.mail" :placeholder="$lang(42625)"></el-input>
        </div>
        <div class="btn">
          <el-button class="btn1" type="primary" @click="check_mail">{{ $lang(42373)}} </el-button>
        </div>

      </div>
      <div v-else>
        <div class="inp1">

          <div class="inp2" > {{put.mail}}</div>
        </div>

        <div class="inp1">
          <span> {{ $lang(42619)}} </span>
          <el-input class="inp2" :placeholder="$lang(42626)" v-model="put.pwd" show-password></el-input>
        </div>
              <div class="inp1">
                <span> {{ $lang(50026)}} </span>
                <el-input class="inp2" :placeholder="$lang(42626)" v-model="put.rpwd" show-password></el-input>
              </div>


        <div class="inp1 ">
          <span> {{ $lang(50129)}} </span>
          <div class="codebox">
            <el-input class="inp2" :placeholder="$lang(50130)" v-model="put.code"  ></el-input>
            <div class="codetxt">
              <span v-if="djs">{{djs}}s </span>

            </div>
            <el-button :disabled="djs > 0" class="    " type="primary" @click="check_mail" circle icon="el-icon-refresh-right"> </el-button>
          </div>
          </div>
        <div class="btn">
          <el-button class="btn1  btnb" type="primary" @click="pre" icon="el-icon-arrow-left" >  </el-button>
          <el-button class="btn1 btnc" type="primary"  @click="sbmt">{{ $lang(50133)}} </el-button>
        </div>
      </div>

      <div class="remark2">
        <span style="color:rgb(250 116 21);" @click="jump('/')">{{ $lang(50120)}} </span> &nbsp; &nbsp;&nbsp;
        <span style="color:rgb(250 116 21);" @click="jump('/login')">{{ $lang(50128)}} </span>

      </div>
    </div>

    <div class="t3">
      <i class="el-icon-close" @click="back()"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      step: 1,
      djs : 0,
      put: {
        mail: '',
        pwd: '',
        code: '',
      }

    }
  },
  methods: {
    pre() {
      this.step = 1
    },
    timeout(){
      if (this.djs <= 0) {
        return
      }
      this.djs--
      setTimeout(() => {
        this.timeout()
      }, 1000);
    },
    check_mail() {
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;

      if (!emailReg.test(this.put.mail)) {
        this.$message({
          type: 'success',
          message: this.$lang(50124)
        });
        return
      }
      this.get("/pub/check_mail", this.put, 'post').then((e) => {

        this.step = 2
        this.djs = 60
        this.timeout()
      });
    },
    resend() {
      this.get("/pub/check_mail", this.put, 'post').then((e) => {

        this.step = 2

      });
    },
    sbmt() {
      if (this.put.pwd.length < 6) {
        this.$message({

          message: this.$lang(50015)
        });
        return;
      }
      if (this.put.pwd != this.put.rpwd) {
        this.$message({

          message: this.$lang(50016)
        });
        return;
      }
      this.get("/pub/repwd", this.put, 'post').then((e) => {
        this.cache('info', e.info);
        this.cache('token', e.token);
        this.$message({
          type: 'success',
          message: this.$lang(50132)
        });
        this.$store.commit('login')
        this.jump('/bus_list', {}, true)

      });
    }
  }
}
</script>

<style scoped>
.codebox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.codebox .inp2{
  width: auto !important;
}
.rtttt {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  line-height: 40px;
  margin-bottom: 10px;
}

.login {
  width: 1100px;
      height: 100vh;
     
        background-repeat: no-repeat;
  margin: 0 auto;
  z-index: 4;
  display: flex;
}

.login .t1 {
  flex: 0 0 50%;
}

.login .t2 {
  margin: 0 auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
}

.login .inp1 {
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
}

.login .inp1 span {
  margin-left: 5%;
  margin-bottom: 5%;
  color: rgb(33, 37, 41);
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0%;
}

.login .inp1 .inp2 {
  width: 280px;
  height: 5vh;
  background: rgb(249, 250, 254);
  border-radius: 8px;
  line-height: 5vh;
  text-align: center;
}

.login .btn {
  display: flex;
}

.login .btn1 {
  min-width: 280px;
  height: 5vh;
  background: rgb(4, 100, 221);
  border-radius: 8px;

}
.login .btnb {
  min-width: 35px;


}
.login .btnc {
  min-width: 200px;


}

.login .remark {
  display: flex;
  margin-top: 5%;
}

.login .remark span {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0%;
  text-align: left;
}

.login .remark2 {
  display: flex;
  margin: 0 auto;
  margin-top: 15%;
}

.login .remark2 span {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0%;

}

.login .t3 {
  margin-top: 3%;
  margin-right: 4%;
}
</style>
