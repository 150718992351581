<template>
  <div class="log">
    <div class="tit1">
      生成历史
    </div>

    <div class="list">
      <div class="itm" v-for=" (vo, i) in log.data" :key="i">
        <div>
          <el-tag>{{ vo.type_str }} </el-tag>
          <el-tag type="success">

            <span> {{ vo.status_str }}</span>
            <span v-if="vo.is_task == 3">

              ({{ vo.sy.ok }}/{{ vo.sy.all }})
            </span>


          </el-tag>
          <span v-if="vo.is_task != 3">
            {{ vo.info.ln }}
            {{ vo.info.fn }}

          </span>
          <el-tag v-if="vo.is_task == 3"> 批次号:{{ vo.batch_sn }}</el-tag>



        </div>
        <div class="cz">
           <el-tag type="info">{{ vo.user.account }} </el-tag>
          <el-tag>{{ vo.created_at }} </el-tag>

          <a :href="vo.url" target="__blank"><el-button type="success" size="mini" v-if="vo.url">
              <span v-if="vo.is_task == 3"> 下载</span>
              <span v-else> 查看 </span>
            </el-button>

          </a>

        </div>
      </div>
    </div>
    <el-pagination background layout="prev, pager, next" :current-page="log.current_page" :page-size="log.per_page"
      :total="log.total" @current-change="change_page">
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
      log: [],
      mode: 'a',
      article_page: 1,
    }
  },

  mounted() {
    this.f5()

  },
  methods: {
    change_page(e) {
      this.article_page = e
      this.f5()
    },
    f5() {
      this.get("/task_log3", { page: this.article_page }, 'get', false).then((e) => {

        this.log = e.list

      });
    },
    del(id) {

      this.get("/del_task", { id: id }, 'get', false).then((e) => {
        this.$message({
          type: 'success',
          message: '删除成功'
        });
        this.f5()
      });
    }
  }
}
</script>

<style scoped lang="less">
.tit1 {
  font-size: 20px;
  padding: 10px 0;
  border-bottom: 4px double #696969;
  width: 90%;
  margin-bottom: 10px;
}

.log {
  flex: 0 0 50%;

  .list {
    width: 90%;

    .itm {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }
  }
}
</style>
