<template>
  <div class="hello">
    <el-dialog :title="info.tit" :visible.sync="show">

      <div v-html="txt">

      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      info: {
        id: 0,
        type: 0,
        tit: '',
      },
      txt: '',
      show: false,
    }
  },
  props: {

    id: {
      type: Number,
      required: false,
      default: 0,
    }
  },
  methods: {

    f5(id) {
      // alert(this.id)
      this.get("/index/page", { id: id }).then((e) => {
        this.info = e.info
        this.txt = this.info[this.cache('lang_config')]
        this.tit = this.info[this.cache('lang_config') + '_tit']
        this.show = true



      });
    },
  }
}
</script>
