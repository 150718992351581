<template>
  <qk_form use_type="esdl_id_base" :field="field"   :select_head="true" :has_back="true" :req="req" title="西班牙ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: 'ID号', placeholder: '不填自动生成 如:BDG107153', },
        { k: 'ads1', title: '具体地址', placeholder: '如:C. LUIS VIVES 27', },
        { k: 'ads2', title: '地名', placeholder: '如:BUÑOL', },
        { k: 'state', title: '县/郡/区', placeholder: '如:BUÑOL', },
        { k: 'PDN', title: '父亲名', placeholder: '如:MIGUEL', },
        { k: 'RT', title: '母亲名', placeholder: '如:FRANCISCA', },
       
       
      ],
      line: '',
      req: [
        'fn', 'ln',   'bor',   'ads1', 'ads2', 'state', 'PDN', 'RT'
      ],


    }
  },
  methods: {
  },



};
</script>
