<template>
  <qk_form use_type="esp_id" :field="field" :req="req" title="西班牙ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: 'BNS040142 不填写自动生成', },
        { k: 'COD', title: '随机号', placeholder: '379052 不填写自动生成', },
        { k: 'PDN', title: 'DNI号', placeholder: '05266284F 不填写自动生成' },

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor',
      ],


    }
  },
  methods: {
  },



};
</script>
