<template>
    <qk_form use_type="rou_dl" :has_back="true" :field="field" :select_head="true" :req="req" title="罗马尼亚-驾照">





    </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
    components: { qk_form },
    name: "index",
    data() {
        return {
            field: [
              
                { k: 'ssn', title: '编号', placeholder: 'I00083892L 不填写自动生成', },
                { k: 'ads1', title: ' 城市名', placeholder: '如 FETESTI, IL', },
                { k: 'ads2', title: ' 县名', placeholder: ' 如 IALOMITA', },
            ],
            line: '',
            req: [
                'fn', 'ln', 'bor', 'ads1', 'ads2'
            ],


        }
    },
    methods: {
    },



};
</script>
