<template>
  <qk_form use_type="ukdl_base" :has_back="true" :field="field" :req="req" title="英国驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号', placeholder: 'GALLA805222GW6YA 87 不填写自动生成', },

        { k: 'ads1', title: '地址', placeholder: '如:4 GATEFORTH COURT,|NORTH YORKSHIRE, YO8 9GX 用|分割', many : 1},

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads1'
      ],


    }
  },
  methods: {
  },



};
</script>
