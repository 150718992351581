<template>
  <div id="app" class="btf_soll">
    <router-view />
  </div>
</template>

<script>
function isMobile() {
  let flag = false;
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    flag = true;
  }
  return flag;
}

export default {
  name: "App",
  data() {
    return {
      conf: {},
      list: [],
      cate: [],
    };
  },
  methods: {
    note_jump(id) {
      if (id == "84") {
        this.jump("/scenic");
      } else {
        this.jump("/article?c=" + id);
      }
    },
  },
  mounted() {
    if (isMobile()) {
      // alert('使用电脑访问获得最佳体验\nAccess with computer for the best experience')
    }
  },
};
</script>
<style>
.reqd::after {
  content: "*";
  color: red;
}
 
</style>
<style   scoped>
.f2 {
  display: flex;
  background: #f8fafd;
  padding: 2vh 6vw;
  color: #333333;
  font-weight: 500;
}

.f2 .tit {
  line-height: 40px;
  height: 40px;
  font-size: 20px;
}

.cuss {
  white-space: nowrap;
  padding: 5px;
}
/* .cuss td, .cuss table {
  border: none !important;
} */
.f2 .l1 {
  flex: 0 0 24%;
  border-right: 1px solid #dadfe6;
}
.f2 .l11 {
  flex: 0 0 25%;
  border-right: 1px solid #dadfe6;
}
.f2 .l2 {
  flex: 0 0 48%;
}

.f2 .row {
  display: flex;
  flex-wrap: wrap;
}
.f2 .row .itmz {
  padding: 5px;
  flex: 0 0 45%;
  cursor: pointer;
  font-size: 14px;
}
.imzzz {
  width: 180px;
}
.f0 {
  background: #f8fafd;
  color: #333333;
  border-bottom: 1px solid #dadfe6;
  height: 30px;
  line-height: 30px;
}
</style> 
<style>
.city_tag {
  background: #eb5100;
  color: #fff;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 2px 4px;
  margin-right: 4px;
  border-radius: 5px;
  min-width: 40px;
  text-align: center;
}

.incoimg {
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 5px;
}
.trtagzz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.booooooz {
  min-height: 98vh;
}
.gb_foot {
  margin-top: 50px;

  text-align: center;

  justify-content: center;
}
.diffzaa {
  margin: 0 20px;
}
.btf_soll {
  overflow-x: hidden;

}
 

.btf_soll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}
.btf_soll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
.btf_soll::-webkit-scrollbar-corner {
  background-color: black;
}
.btf_soll::-webkit-scrollbar-button {
  background-color: rgb(189, 45, 45);
  color: #f5f5f5;
  display: none;
}
.btf_soll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.time_division_type {
  background-color: #0464dd !important;
  color: #fff !important;
}
 
.t1 {
  width: 80vw;
  margin: auto;
}
 

.contt {
  margin-top: 20px; 
  /* border-radius: 5px; */
  padding-left: 40px;
}
 
</style>
