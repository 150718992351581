// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import qk_form from '@/components/qk_form.vue';
import point_tc from '@/components/point_tc.vue';
import page_tc from '@/components/page_tc.vue';
import page_cont from '@/components/page_cont.vue';
import store from './store'
import { get } from '@/utils/request'
// import 'font-awesome/css/font-awesome.min.css'
Vue.prototype.$axios = axios
Vue.component('qk_form', qk_form)
Vue.component('point_tc', point_tc)
Vue.component('page_tc', page_tc)
Vue.component('page_cont', page_cont)
Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.get = get
Vue.prototype.web_tit = '乐行巴士-日本巴士预订订票官网，日本夜行巴士，日本昼行巴士，日本旅游巴士，日本旅行巴士'
Vue.prototype.$store = store

Vue.prototype.to_hi = function (datetime) {
  if (!datetime) {
    return ''
  }
  return datetime.substr(0, 16)
}

Vue.prototype.to_ym = function (datetime) {
  if (!datetime) {
    return ''
  }
  return datetime.substr(0, 7)
}

Vue.prototype.admin_url = 'http://api.japanbus.cn'
Vue.prototype.city_dis = function (s) {
  if (!s) {
    return ''
  }
  return s.split('(')[0].split('（')[0]
}



Vue.prototype.to_ymd = function (datetime) {
  if (!datetime) {
    return ''
  }
  return datetime.substr(0, 10)
}


function cache(k, v) {
  // console.log('dodo', v)
  if (typeof (v) == 'undefined') {
    // get
    // console.log('get')
    return JSON.parse(localStorage.getItem(k));
  } else if (v == null) {
    // del
    // console.log('del')
    localStorage.removeItem(k);
  } else {
    // set
    // console.log('set', v)
    localStorage.setItem(k, JSON.stringify(v));
  }

}

Vue.prototype.cache = cache


  Vue.prototype.$lang = function (k) {
     return '--'

  }

  Vue.prototype.jump2 = function (url) {
    cache('jump_to', url)
    router.push({ path: '/jump/to', query: {}, replace: true })
  }
  Vue.prototype.jump = function (url, para = {}, replace = false) {

    router.push({ path: url, query: para, replace: replace })
  }
  Vue.prototype.back = function () {

    router.back()
  }

  Vue.prototype.decimal = function (v, len = 2) {
    return parseFloat(v).toFixed(len)

  }

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


 
