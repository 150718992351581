<template>
  <qk_form use_type="singapore_passport_base" :field="field" :req="req" title="新加坡护照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: 'E1992598B 不填写自动生成', },
        { k: 'idcard', title: 'id号', placeholder: 's7657766u 不填写自动生成', },


      ],
      line: '',
      req: [
        'fn', 'ln', 'bor',
      ],


    }
  },
  methods: {
  },



};
</script>
