<template>
  <qk_form use_type="nldl_id_base" :field="field" :has_back="true"  :select_head="true"  :req="req" title="荷兰ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: 'NN970LL48 不填写自动生成', },
        { k: 'idcard', title: '身份证ID号', placeholder: '136236613 不填写自动生成', },
        { k: 'state', title: '出生地', placeholder: '如:Arnhem', },
        { k: 'RT', title: '发证机关', placeholder: '默认: Zoetermeer ', },




      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state',
      ],


    }
  },
  methods: {
  },



};
</script>
