<template>
  <qk_form use_type="ukpassport_base" :field="field" :select_head="true" :req="req" title="英国护照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: '512267371 不填写自动生成', },
        { k: 'state', title: '出生地', placeholder: '出生地', },
      ],
      line: '',
      req: [
        'fn', 'ln',   'bor', 'state'
      ],


    }
  },
  methods: {
  },



};
</script>
