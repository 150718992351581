<template>
  <qk_form use_type="usid" :field="field" :req="req" title="美国ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field : [
        {  k : 'PDN', title : 'Passport Card no', placeholder : 'O00000000 不填写自动生成',},
        { k: 'COD', title: '左下角编码', placeholder: 'G-0000000-00 不填写自动生成', },
        { k: 'RT', title: '右下角', placeholder: '1-00000-0 不填写自动生成', }
      ],
      line: '',
      req: [
        'fn', 'ln',  'bor'
      ],


    }
  },
  methods: {
  },



};
</script>
