<template>
  <qk_form use_type="frdl" :has_back="true" :field="field" :req="req" title="法国驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: '13EF22586 不填写自动生成', },
        { k: 'ads2', title: '出生地（省名）', placeholder: '如:Sury-le-Comtal', },
        { k: 'ads1', title: '发证机构（邮编+城市名）', placeholder: '如:42450 Sury Le Comtal'},

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads2', 'ads1'
      ],


    }
  },
  methods: {
  },



};
</script>
