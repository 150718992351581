<template>
  <qk_form use_type="audl_vic" :has_back="true" :select_head="true" :field="field" :req="req" title="澳大利亚-VIC-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [

        { k: 'ssn', title: '编号', placeholder: '156055668 不填写自动生成', },
         { k: 'ads1', title: '地址', placeholder: '如:23 SOMEWHERE STREET|BRISBANE 4000 用|分割', many: 1 },
      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads1',  
      ],


    }
  },
  methods: {
  },



};
</script>
