<template>
  <qk_form use_type="hkid" :field="field" :req="req" title="香港ID">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '编号', placeholder: '5591 3521 0193 不填写自动生成', },
        { k: 'idcard', title: '身份证号', placeholder: "Z139989 不填写自动生成", },

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor',
      ],


    }
  },
  methods: {
  },



};
</script>
