<template>
    <div class="login">
        <div class="t1">

        </div>
        <div class="t2">
            <div class="inp1">
                <span>设置密码</span>
                <el-input  class="inp2" placeholder="请设置您的密码" v-model="password" show-password></el-input>
            </div>
            <div class="inp1">
                <span>确认密码</span>
                <el-input  class="inp2" placeholder="请您确认您的密码" v-model="password" show-password></el-input>
            </div>
            <div class="btn">
                <el-button class="btn1" type="primary">确认</el-button>
            </div>
 
            <div class="remark2">
                <span>首次登录，请设置您的</span>
                <span style="color:rgb(250 116 21);">密码</span>
            </div>
        </div>

        <div class="t3">
            <i class="el-icon-close"></i>
        </div>
    </div>
</template>

<script>
export default {
    name:"edit_login",
    data() {
        return {
            email :'',
            password: '',
        }
    }
}
</script>

<style scoped>
.login{
    width: 1100px;
    height: 540px; 
    margin: 0 auto;
    z-index: 4;
    display: flex;
}
.login .t1{
    flex: 0 0 50%;
}
.login .t2{
    margin: 0 auto;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
}
.login .inp1{
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
}
.login .inp1 span{
    margin-left: 5%;
    margin-bottom: 5%;
    color: rgb(33, 37, 41);
    font-family: Noto Sans SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0%;
}
.login .inp1 .inp2{
    width: 280px;
    height: 5vh;
    background: rgb(249, 250, 254);
    border-radius: 8px;
}
.login .btn1{
    width: 280px;
    height: 5vh;
    background: rgb(4, 100, 221);
    border-radius: 8px;

}
.login .remark2{
    display: flex;
    margin: 0 auto;
    margin-top: 15%;
}
.login .remark2 span{
    font-family: Noto Sans SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    
}
.login .t3 {
    margin-top: 3%;
    margin-right: 4%;
}
</style>