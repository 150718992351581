<template>
  <qk_form use_type="nld_passport" :field="field" :req="req" title="荷兰护照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '护照号', placeholder: 'NT7EB 不填写自动生成', },
        { k: 'state', title: '出生地', placeholder: '如 Houten', },




      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state',
      ],


    }
  },
  methods: {
  },



};
</script>
