<template>
  <div class="rrrrrr">


    <div class="lab">
      快速填写
    </div>
    <div class="ipt">
      <el-radio v-model="mode" label="b">模式1</el-radio>
      <el-radio v-model="mode" label="a">模式2</el-radio>

    </div>

    <el-input placeholder="文本行" @input="out_arr" v-model="line"></el-input>
    <br>


    <p v-if="mode == 'b'">
      如:
      GILBERTO IBARRA|5106490344|5106490343|1337 UNIVERSITY AVE APT 4|BERKELEY CA
      94702-1763|BRK|94702|GIBARRAESTRADABARRIOS@GMAII.COM|1969-02-23|574115547|
    </p>
    <p v-if="mode == 'a'">
      <span style="color:red">请从表格中粘贴数据</span>
      如:
      WILLIAM BURNS 2601 COMMERCIAL AVE APT 10 S CHICAGO HTS IL 60411 7088451784 11/29/1956 347526996

    </p>
    <div style="color:rgb(41, 30, 201)">
      批量生成 : 选择文件后自动添加数据
    </div>

    <input type="file" v-show="mode == 'a'" accept="application/vnd.ms-excel" id="excel-file" />
    <input type="file" v-show="mode == 'b'"   accept="text/plain"  id="text-file" />
  </div>

</template>

<script> 
import { stat, STATE_CONF } from '@/utils/stat'
export default {
  name: 'HelloWorld',
  data() {
    return {
      line: '',
      mode: 'b',
    }
  },
  mounted(){
     let ths = this
    const fileInput = document.getElementById('excel-file');
     

     const textInput = document.getElementById('text-file');
    textInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      e.target.value = ''
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        const data = e.target.result;
        console.log(data)
        let lst = data.split('\r\n')
        console.log(lst)
        let timestamp = new Date().getTime()
        let bas = []
        for (const arr of lst) {
          console.log(arr)
          if (arr) {
            bas.push(ths.out2(arr.split('|'), true))
          }

        }
        this.$emit('out', bas, true, timestamp)

      }
    });
  },
  methods: {
    out_arr() {
      console.log(this.line)


      let arr = []
      if (this.mode == 'a') {
        arr = this.line.split('\t')
        if (arr.length < 3) {
          arr = this.line.split('	')
        }
      }
      if (this.mode == 'b') {
        arr = this.line.split('|')
      }

      console.log(arr)

      this.out2(arr)

    },
    out2(arr, rtn = false) {
      let out = {
        name: ''
      }
       let map = []
      if (this.mode == 'a') {
        map = ['ln', 'fn', 'ads1', 'ads2', 'state', 'post', 'tel', 'bor', 'ssn']
      } else {
        map = ['name', 'tel1', 'tel2', 'ads1', 'ads2', 'city', 'post', 'mail', 'bor', 'ssn']
      }
      if (this.mode == 'b' && arr.length < 9) {
        return
      }
      if (this.mode == 'a' && arr.length < 9) {
        return
      }
      for (let i in map) {
        out[map[i]] = arr[i]
      }

      if (out.name) {
        let name = out.name.split(' ')
        out['ln'] = name[0]
        out['fn'] = ''
        for (let i = 0; i < name.length - 1; i++) {
          out['fn'] += name[i + 1] + ' '
        }
      }
      if (this.mode == 'a') {

        let [day, month, year] = out['bor'].split('/');
        out['bor'] = `${year}-${day}-${month}`;
      }

      if (this.mode == 'b') {
        for (let k in STATE_CONF) {
          if (arr[4].indexOf(` ${k} `) !== -1) {
            out['state'] = k
            break
          }
        }
      }
      console.log(out)
      if (!rtn) {
         this.$emit('out', out)
      } else {
        return out
      }

    },
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 400px;
  border: 1px solid #000;
  overflow: hidden;
}

.map2 {
  width: 110%;
  height: 400px;
}
</style>
