<template>
  <div class="foottt">
 
  </div>
</template>

<script>

 
export default {
  name: 'nav_list',
  props:
  {
    show_txt: {
      default: 1,
      type: Number,
    }
  },

  data() {
    return {
      

    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>
 