<template>
  <div class="hhhome">
    <div class="bar">
         <div class="hy">
            <img src="/logo.png" alt="" @click="show_form('')">

         </div>
      <div class="right">
        <span>
          <div
            class="itmaa"
            v-if="!$store.state.is_login"
            style="flex: 0 0 25%"
          >
            <span @click="jump('/login')">
              <i class="el-icon-user"></i> {{ this.$lang("50115") }}</span
            >
            |
            <span @click="jump('/reg')">
              <i class="el-icon-edit-outline"></i>
              {{ this.$lang("50118") }}</span
            >
          </div>
          <div v-else class="itmaa">
            <div class="bdaa itmaa">
              <span class="dzdzd"> {{ info.account || info.mail }}</span>

              <div class="bag">
                <el-badge :value="$store.state.money" type="warning">
                  <i class="el-icon-shopping-bag-2"></i>
                </el-badge>
              </div>
            </div>
       
                        <div  class="bdaa">
              
              <span > 
               邀请码 {{ info.code }} <i @click="refresh_code" class="el-icon-refresh"></i>
              </span> 

             
            
            </div>

            <div class="bdaa" @click="quit">
              <i class="el-icon-close"></i> 退出登录
            </div>
          </div>
        </span>
      </div>
    </div>

    <div class="main">
      <div class="nav btf_soll">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 美国 America</template>
            <div class="row" name="1-1" @click="show_form('/us/passport')">
              护照
            </div>
            <div class="row" name="1-2" @click="show_form('/us/id')">ID卡</div>
            <div class="row" name="1-3" @click="show_form('/us/driver')">
              驾照
            </div>
            <div class="row" name="1-4" @click="show_form('/us/ssn')">SSN</div>
            <div class="row" name="1-4" @click="show_form('/us/invoice')">
              账单
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 英国 Britain</template>
            <div class="row" name="2-1" @click="show_form('/uk/passport')">
              护照
            </div>
            <div class="row" name="2-2" @click="show_form('/uk/dl')">驾照</div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 德国 Germany</template>
            <div class="row" name="3-1" @click="show_form('/de/dl')">驾照</div>
            <div class="row" name="3-1" @click="show_form('/de/passport')">
              护照
            </div>
            <div class="row" name="3-1" @click="show_form('/de/invoice')">
              账单
            </div>
            <div class="row" name="3-1" @click="show_form('/de/de_invoice_tel')"  v-if="info.account == 'shj29292'" >
              话费账单
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 澳大利亚 Australia</template>
            <div class="row" name="4-1" @click="show_form('/au/passport')">
              护照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_qld')">
              QLD-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_nsw')">
              NSW-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_tas')">
              TAS-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_vic')">
              VIC-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_wa')">
              WA-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_act')">
              ACT-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_sa')">
              SA-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dl_nt')">
              NT-驾照
            </div>
            <div class="row" name="4-2" @click="show_form('/au/dian_in')">
              电费账单
            </div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 台湾 TaiWan</template>
            <div class="row" name="5-1" @click="show_form('/taiwan/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="6">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 法国 France</template>
            <div class="row" name="6-1" @click="show_form('/fr/passport')">
              护照
            </div>
            <div class="row" name="6-2" @click="show_form('/fr/dl')">驾照</div>
            <div class="row" name="6-3" @click="show_form('/fr/id')">ID</div>
          </el-collapse-item>
          <el-collapse-item name="7">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 新加坡 Singapore</template>
            <div
              class="row"
              name="7-1"
              @click="show_form('/singapore/passport')"
            >
              护照
            </div>
            <div class="row" name="7-2" @click="show_form('/singapore/dl')">
              驾照
            </div>
          </el-collapse-item>
          <el-collapse-item name="8">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 意大利 Italy</template>
            <div class="row" name="8-1" @click="show_form('/italy/dl')">
              驾照
            </div>
            <div class="row" name="8-1" @click="show_form('/italy/z1')">
              水电账单1
            </div>
            <div class="row" name="8-1" @click="show_form('/italy/z2')" v-if="info.account == 'shj29292'">
              燃气账单
            </div>
          </el-collapse-item>

          <el-collapse-item name="9">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 香港 HongKong</template>
            <div class="row" name="9-1" @click="show_form('/hk/id')">ID</div>
          </el-collapse-item>

          <el-collapse-item name="10">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 日本 Japan</template>
            <div class="row" name="9-1" @click="show_form('/jp/passport')">
              护照
            </div>
            <div class="row" name="9-2" @click="show_form('/jp/dl')">驾照</div>
          </el-collapse-item>

          <el-collapse-item name="12">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 马来西亚 MYS</template>
            <div class="row" name="12-1" @click="show_form('/mys/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="13">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 菲律宾 PHL</template>
            <div class="row" name="13-1" @click="show_form('/phl/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="14">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 波兰 POL</template>
            <div class="row" name="14-1" @click="show_form('/pol/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="15">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 瑞典 SWE</template>
            <div class="row" name="15-1" @click="show_form('/swe/dl')">
              驾照
            </div>
          </el-collapse-item>
          <el-collapse-item name="16">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 比利时 BELG </template>
            <div class="row" name="16-1" @click="show_form('/belg/id')">ID</div>
          </el-collapse-item>
          <el-collapse-item name="17">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 荷兰 NLD </template>
            <div class="row" name="17-1" @click="show_form('/nld/id')">ID</div>
            
            <div class="row" name="17-2" @click="show_form('/nld/passport')">
              护照
            </div>
            <div class="row" name="17-1" @click="show_form('/nld/z1')">水电账单</div>
          </el-collapse-item>
          <el-collapse-item name="18">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 瑞士 CH </template>
            <div class="row" name="18-1" @click="show_form('/ch/dl')">驾照</div>
          </el-collapse-item>
          <el-collapse-item name="19">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 斯里兰卡 LK </template>
            <div class="row" name="19-1" @click="show_form('/slk/dl')">
              驾照
            </div>
          </el-collapse-item>
          <el-collapse-item name="20">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 罗马尼亚 ROU </template>
            <div class="row" name="20-1" @click="show_form('/rou/dl')">
              驾照
            </div>
            <div class="row" name="20-1" @click="show_form('/rou/r1')">
              燃气账单
            </div>
          </el-collapse-item>
          <el-collapse-item name="21">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 西班牙 ES </template>
            <div class="row" name="21-1" @click="show_form('/es/dl')">驾照</div>
            <div class="row" name="21-1" @click="show_form('/es/id')">ID</div>
            <div class="row" name="21-1" @click="show_form('/es/ino_orange')">orange账单</div>
            <div class="row" name="21-1" @click="show_form('/es/shuidian')">水电账单</div>
            <div class="row" name="21-1" @click="show_form('/es/z2')" v-if="info.account == 'shj29292'" >Endesa Energy电费账单</div>
          </el-collapse-item>
          <el-collapse-item name="22">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 加拿大 CAN </template>
            <div class="row" name="22-1" @click="show_form('/can/id')">ID</div>
            <div class="row" name="22-2" @click="show_form('/can/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="23">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 新西兰 NZ </template>
            <div class="row" name="23-1" @click="show_form('/nz/dl')">驾照</div>
            <div class="row" name="23-2" @click="show_form('/nz/passport')">
              护照
            </div>
          </el-collapse-item>
          <el-collapse-item name="24">
            <template slot="title"> <i class="header-icon el-icon-info"></i> 印尼 indonesia </template>
            <div class="row" name="24-1" @click="show_form('/indonesia/dl')">
              驾照
            </div>
            <!-- <div class="row" name="23-2" @click="show_form('/nz/passport')">护照</div> -->
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="form">
        <iframe class="btf_soll" v-if="url" :src="url" frameborder="0"></iframe>
        <div v-else> 

            <div v-for="(vo, i) in notes" :key="i" class="zdz">
                <div class="l1">
                    {{vo.title}}
                </div>
                <div class="l2">
                     {{vo.created_at}}
                </div>
            </div>
        </div>
      </div>
      <div class="task">
        <task></task>
      </div>
    </div>
  </div>
</template>

<script>
import task from "@/components/task.vue";

import stat from "@/utils/stat";
console.log(stat);
export default {
  name: "name",
  data() {
    return {
      activeName: "0",
      url: "",
      info: {},
      lang: "",
      show: true,
        notes : [],
      code: "",
    };
  },
  methods: {
    show_form(u) {
        if (u) {
                const timestamp = new Date().getTime(); //
        let x =   u+"?t="+timestamp
        console.log(x)
      this.url = u+"?id="+timestamp;
        } else {
            this.url = ''
        }
    
    },
    quit() {
      this.$message({
        type: "success",
        message: "ok",
      });

      this.cache("info", "");
      this.cache("token", "");
      this.$store.commit("quit");
      this.jump("/", {}, true);
    },

    refresh_code() {
      this.get("/refresh_code", {}, "get", false).then((e) => {
        this.info.code = e.code;
        this.cache("info", this.info);
      });
    },
  },
  mounted() {
    if (this.cache("token")) {
      this.$store.commit("login");
      this.info = this.cache('info')

      this.get("/user", {}, "get", false).then((e) => {
        e.user["code"] = e.code;
        this.info = e.user;
        this.notes = e.list;
        console.log("dd", this.info);
        this.$store.commit("set_money", parseInt(e.user.money));
      });
    } else {
         this.jump('/')
    }
  },
  components: {
    //注册

    task,
  },
};
</script>

<style  lang="less" scoped>
.main {
  display: flex;
  .nav {
    flex: 0 0 20%;
    max-height: 90vh;
    overflow-y: scroll;
  }
  .form {
    flex: 0 0 50%;
    padding: 10px;
    iframe {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
    }
  }
  .task {
    border-left: 1px solid #eee;
    flex: 0 0 28%;
  }
}

.bar {
    // height:50px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  align-items: center;
//   background: #6d6d6d;
}

.hy {
    flex: 0 0 30%;
    img {
        width: 110px;
    }
}
.right {
    flex: 0 0 25%;
}
.itmaa {
  margin-left: 3%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  cursor: pointer;
  /* color: #1c5098; */
  display: flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;
}

.itmaa .bdaa {
  border-right: 1px solid #eee;
  min-width: 100px;
  padding: 0 30px;
  text-align: center;
  color: #6d6d6d;
}

.itmaa .bdaa:last-child {
  border-right: none;
}
.zdz {
    text-align: center;
    padding: 20px;
    box-shadow: 4px 4px 4px #eee;
    margin: 15px auto;
    width: 80%;
    .l1 {

    }
    .l2 {
        text-align: right;
        color: #6d6d6d;
        font-size: 13px;
    }
}
</style>
