<template>
   <div>
   	  
      <ImgCutter @cutDown="cutDown" :cutWidth="400" :cutHeight="400"> </ImgCutter>
   </div>
</template>

<script>
import ImgCutter from 'vue-img-cutter'

export default {
    name: 'imgCutter',
    data() {
       return {
          
       }
    },
    components:{
        ImgCutter
    },
    methods: {
       //通过cutDown事件接收选择的图片信息
       cutDown(event) {
           console.log(event)
       }
    }
}
</script>
