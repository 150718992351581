<template>
  <qk_form use_type="es_dl" :field="field"  :diy_head = "true" :select_head="true"  :has_back="true" :req="req" title="西班牙驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号', placeholder: '01234567-X 不填写自动生成', },
       
       
      ],
      line: '',
      req: [
        'fn', 'ln',   'bor',   
      ],


    }
  },
  methods: {
  },



};
</script>
