import { render, staticRenderFns } from "./point_tc.vue?vue&type=template&id=69f0896b&scoped=true&"
import script from "./point_tc.vue?vue&type=script&lang=js&"
export * from "./point_tc.vue?vue&type=script&lang=js&"
import style0 from "./point_tc.vue?vue&type=style&index=0&id=69f0896b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69f0896b",
  null
  
)

export default component.exports