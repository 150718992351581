<template>
  <div class="t1">
     

 

  </div>
</template>

<script>
import nav_list from '@/pages/index/nav_list.vue';
import foot from '@/pages/index/foot.vue';
import task_admin from '@/components/task_admin.vue';
import task from '@/components/task.vue';

export default {
  name: "user",

  data() {
    return {
      user: {
        is_admin: -1,
      },
      sub: {},
      log: {},
      code: '',
      sub_count: 0,
      trees: [],
      dialogVisible:false,
      to_tit: '',
      to_i: -1,
      to_id: 0,
      to_my:0,

    }
  },
  methods: {
    

  },
  mounted() {
     


  },
  components: {  //注册
    nav_list, foot, task_admin, task
  }

};
</script>
<style lang="less" scoped>
.h1 {
  display: flex;
  justify-content: space-between;

  .itm {
    flex: 0 0 47%;

    padding: 20px;

    .txtrow {
      box-shadow: 10px 25px 10px -16px rgba(0, 0, 0, 0.5);
      margin-top: 30px;
      // border-bottom: 1px solid #000;
      padding: 34px;
      width: 90%;
      background: #fff;
      // background: linear-gradient(to right, #fdfdfd, rgb(247, 247, 247));
      // border: 1px solid #fed8002f;
      border-left: 3px solid #fed700;
      border-top: 3px solid #fed700;
      border-radius: 5px;

      .lab {
        font-weight: bold;
        font-size: 16px;
        padding: 0 20px;
        color: #696969;

      }
    }
  }
}

h3 {
  color: #696969;
}

.contt {
  padding: 30px;
  // border: none;
  background: #fafafa;
  //  border: 4px double #fed700;
}

.vd {
  height: 20px;
  border-top: #f0f0f0 solid 1px;
  margin-top: 10px;
}

.list {
  width: 90%;
  padding-top: 30px;

  .itma {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.tabz {
  width: 100%;
}</style>
