<template>
  <div class="form">
    <div class="put">
      <div class="tit1">
        {{ title }}
      </div>

      <!-- <quk_in @out="qk"></quk_in> -->

      <template v-if="use_type == 'taiwanpassport' || use_type == 'hkid'">
        <div class="row">
          <div class="lab reqd">姓:</div>
          <div class="ipt">
            <el-input
              placeholder="王 (必须是中文)"
              suffix-icon="el-icon-date"
              v-model="put.ln"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="lab reqd">名:</div>
          <div class="ipt">
            <el-input
              placeholder="二小 (必须是中文)"
              suffix-icon="el-icon-date"
              v-model="put.fn"
            ></el-input>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="lab reqd">Frist name 名字:</div>
          <div class="ipt">
            <el-input
              placeholder="包含中间名 用空格格开"
              @input="checkname"
              suffix-icon="el-icon-date"
              v-model="put.fn"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="lab reqd">Last name 姓氏:</div>
          <div class="ipt">
            <el-input
              placeholder=" Last name"
              suffix-icon="el-icon-date"
              v-model="put.ln"
            ></el-input>
          </div>
        </div>
      </template>
      <div class="row" v-if="use_type !== 'hkid'">
        <div class="lab">Issued On 签发日:</div>
        <div class="ipt">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="put.iis"
            type="date"
            placeholder="Issued On 不填写自动生成"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="lab reqd">性别:</div>
        <div class="ipt">
          <el-radio v-model="put.sex" label="M" @input="put.head = ''"
            >男</el-radio
          >
          <el-radio v-model="put.sex" label="F" @input="put.head = ''"
            >女</el-radio
          >
        </div>
      </div>
      <div class="row">
        <div class="lab reqd">生日:</div>
        <div class="ipt">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="put.bor"
            type="date"
            placeholder="选择生日"
          >
          </el-date-picker>
        </div>
      </div>

      <!-- <slot/> -->
      <div class="row" v-if="use_type == 'us_passport_base'">
        <div class="lab reqd">出生地</div>
        <div class="ipt">
          <el-select filterable v-model="put.state" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row" v-if="use_type == 'italy_dl'">
        <div class="lab reqd">区/县名</div>
        <div class="ipt">
          <el-select filterable v-model="put.state" placeholder="请选择">
            <el-option
              v-for="item in idl"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row" v-for="(vo, i) in field" :key="i">
        <div class="lab" v-bind:class="{ reqd: req.indexOf(vo.k) !== -1 }">
          {{ vo.title }}:
        </div>
        <div class="ipt">
          <el-input
            :placeholder="vo.placeholder"
            :rows="6"
            :type="vo.many ? 'textarea' : ''"
            v-model="put[vo.k]"
          ></el-input>
        </div>
      </div>
      <div class="row" v-if="select_head">
        <div class="lab">选择头像</div>
        <div class="ipt">
          <div class="headss">
            <div
              class="headr"
              v-bind:class="{ act_head: put.head == '' }"
              @click="put.head = ''"
            >
              <div class="headnull">根据年龄随机</div>
            </div>

            <div
              class="headr"
              v-if="diy_head"
              v-bind:class="{ act_head: put.head == 'diy' }"
              @click="diy_s1"
            >
              <div class="headnull">自定义上传头像</div>
            </div>

            <div
              class="headr"
              v-for="uu in heads[put.sex]"
              @click="put.head = uu.k"
              v-bind:class="{ act_head: put.head == uu.k }"
              :key="uu.k"
            >
              <img :src="`/head2${uu.url}`" />
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="has_back">
        <div class="lab">生成背面:</div>
        <div class="ipt">
          <el-switch
            v-model="put.mk_back"
            active-text="生成(费用+1)"
            inactive-text="不生成"
          >
          </el-switch>
        </div>
      </div>

      <div>
        <el-button type="success" @click="mk"
          >生成 - {{ mkphoto }}<i class="el-icon-coin"></i
        ></el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span> 当前余额 : <i class="el-icon-coin"></i>{{ user.money }}</span>
      </div>
    </div>

    <el-dialog title="自定义头像" :visible.sync="diy_show" width="80%">
      <div>
        <div class="img_bg" style="background-image: url('/head2/huibai.jpg')">
          <img :src="put.diy_img" alt="" />
        </div>

        <div class="tips">
            上方图框显示图片后表示图片已处理完成,如果去除背景效果不理想 就换一张图片. <br>
            如果可以  就点击右上角的关闭
        </div>
        <ImgCutter @cutDown="cutDown" :cutWidth="290" :cutHeight="370">
        </ImgCutter>

        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import nav_list from "@/pages/index/nav_list.vue";
import foot from "@/pages/index/foot.vue";
import task from "@/components/task.vue";
import quk_in from "@/components/quk_in.vue";
import stat from "@/utils/stat";
import { removeBackground } from "@imgly/background-removal";
import ImgCutter from "vue-img-cutter";

let ydl = `Agrigento(AG) 阿格里琴托
Alessandria(AL)亚里山德里亚
Ancona(AN) 安科纳
Aosta(AO)奥斯塔
Arezo(AR)阿雷佐
Ascoli Piceno(AP) 阿斯科里·皮切诺
Asti(AT) 阿斯蒂
Avellino(AV) 阿韦里诺
Bari(BA)巴里
Belluno(BL)贝卢诺
Benevento(BN) 贝内文托
Bergamo(BG) 贝尔加莫
Bologna(BO)波沦亚
Bolzano(BZ) 波尔察诺
Brescia(BS)布雷西亚
Brindisi(BR) 布林的西
Cagliari(CA)卡利亚里
Calabria(CB)卡拉布里亚
Caltanissetta(CL)卡尔塔尼塞塔
Campobasso(CB) 卡波巴索
Caserta(CE) 卡塞塔
Catania(CT) 卡塔尼亚
Catanzaro(CZ) 卡坦扎诺
Chieti(CH)基埃蒂
Como(CO)科莫
Corno Grande(CS) 大科尔诺山
Cosenza(CS)科森察
Cremona(CR)克雷莫纳
Cuneo(CN)库内奥
SANT'ANGELO LODIGIANO(LO)--
CATTOLICA(RN)不知道 
Reggio Emilia(RE) 勒佐·艾米里亚
Enna(EN)恩纳
Ferrara(FE)费拉拉
Firenze(FI)佛罗伦萨
Foggio(FG)福贾
Forli(FO)弗利
Frosinone(FR)弗罗西诺内
Genova(GE)热拉亚
Gorizia(GO) 戈里齐亚
Grossetto(GR)格罗塞托
Imperia(IM)因佩里亚
Isernia(IS)伊塞尼亚
L’Aquila(AQ)拉圭纳
La Spezia(SP)拉斯佩齐亚
Latina(LT)拉蒂纳
Lecce(LE)莱切
Livorno(LI)里沃诺
Lucca(LU)卢卡
Macerate(MC)马切拉塔
MONTEGRANARO(FM)--
Mantova(MN)曼图瓦
MERATE(LC)--
VIMERCATE(MB)--
Massa Carrara(MS)马萨卡拉拉
Matera(MT)马特拉
Messina(ME)墨西拿
Milano(MI)米兰
Modena(MO)莫德纳
Napoli(NA)那不勒斯 / 那波里
Novara(NO)诺瓦拉
Nuoro(NU)努奥诺
Oristano(OR)奥利斯塔诺
Padova(PD)帕托瓦
Palermo(PA)巴勒莫
Pavia(PV) 帕维亚
Perma(PR) 帕尔马
Perugia(PG)佩鲁佳
Pesaro(PS) 佩萨罗
Pescara(PE) 佩斯卡拉
Piacenza(PC)皮亚琴察
PRATO(PO)--
CESENA(FC)不知道
Pisa(PI)比萨
Pistoia(PT)皮斯托亚
Pordenone(PN)波尔德诺内
Potenza(PZ)波坦察
Perugia(PU)佩鲁贾
Ragusa(RG)拉古萨
Ravenna(RA) 拉文纳
Reggio Calabria(RC)勒佐·卡拉布里亚
Rieti(RI)里埃蒂
Roma(RM)罗马
Rovigo(RO)罗维戈
Salerno(SA)萨勒诺
Sassari(SS) 萨里
Savona(SV) 萨沃纳
Siena(SI)锡耶纳
Siracusa(SR) 锡拉库扎
Sondrio(SO)嵩德里奥
TRINITAPOLI(BT)--
Taranto(TA)塔兰托
Teramo(TE)德拉莫
Terni(TR) 德尔尼
Torino(TO)都灵
Trapani(TP)特拉巴尼
Trento(TN)特兰托
Treviso(TV)特雷维佐
Trieste(TS)的里雅斯特
Udine(UD)乌迪内
Varese(VA)瓦勒塞
Venezia(VE)威尼斯
Vercelli(VC)维切利
Verona(VR) 维罗纳
Vicenza(VI) 维琴察
Viterbo(VT) 维泰尔博`;
let arr = ydl.split("\n");
let idl = [];
for (let vovo of arr) {
  idl.push(vovo.split(")")[0] + ")");
}
console.log(idl);
function create_head(pre, p2, s, e) {
  let r = [];
  for (let i = s; i <= e; i++) {
    let key = (i + "").padStart(3, "0");
    key = pre + key + ".jpg";
    r.push({
      url: key,
      k: p2 + (i + "").padStart(3, "0"),
    });
    // :src="`../../static/${(i + '').padStart(3, '0')}.png`"
  }
  return r;
}
let heads = {
  M: create_head("/M/L/L", "L", 1, 50).concat(
    create_head("/M/Z/Z", "Z", 1, 50),
    create_head("/M/Q/Q", "Q", 1, 50)
  ),
  F: create_head("/F/L/L", "L", 1, 50).concat(
    create_head("/F/Z/Z", "Z", 1, 50),
    create_head("/F/Q/Q", "Q", 1, 54)
  ),
};
console.log(heads);
export default {
  name: "qk_form",
  props: {
    title: "",
    use_type: "",
    req: {
      default: ["fn", "ln", "ssn", "bor"],
      type: Array,
    },
    has_back: false,
    select_head: false,
    diy_head: false,
    field: [],
  },
  data() {
    return {
      user: {},
      heads: heads,
      put: {
        fn: "",
        ln: "",
        name: "",
        idcard: "",
        bor: "",
        sex: "M",
        COD: "",
        PDN: "",
        RT: "",
        iis: "",
        ssn: "",
        ssn2: "",
        light: "y",
        state: "",
        ads1: "",
        ads2: "",
        ads3: "",
        rate: "n",
        head: "",
        mk_back: false,
        get_conf_state: "USAID",
        diy_img: "",
      },
      options: stat.stat,
      value: "",
      mkphoto: -1,
      log: [],
      out: "",
      activeName: "line",
      line: "",
      idl: idl,
      diy_show: false,
    };
  },
  methods: {
    isChinese(text) {
      var re = /[\u4e00-\u9fa5]/;
      return text.match(re);
    },
    async checkname() {
      if (!this.put.fn) {
        return;
      }
      await this.get(`checkname/${this.put.fn}`).then((e) => {
        if (e.sex == "Male") {
          this.put.sex = "M";
        } else if (e.sex == "Female") {
          this.put.sex = "F";
        } else {
          // this.$message({
          //   type: 'success',
          //   message: '性别获取失败'
          // });
        }
      });
    },
    async qk(out, batch = false, tm = "") {
      if (!batch) {
        for (let k in this.put) {
          if (k in out) {
            this.put[k] = out[k];
          }
        }
        await this.checkname();
      } else {
        this.put.batch_sn = tm;

        for (const ot of out) {
          for (let k in this.put) {
            if (k in ot) {
              this.put[k] = ot[k];
            }
          }
          await this.checkname();
          await this.mk();
        }

        this.get("batch_start", { batch_sn: this.put.batch_sn }, "post").then(
          (e) => {
            this.$message({
              message: "批量任务已开始",
            });
          }
        );

        this.put.batch_sn = "";
      }
    },
    isValidDate(date) {
      var regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
      return regex.test(date);
    },
    async mk() {
      this.out = "";
      let p = "";
      if (!this.isValidDate(this.put.bor)) {
        this.$message({
          message: "生日格式错误",
        });
        return;
      }
      for (let k in this.put) {
        if (this.req.indexOf(k) !== -1 && !this.put[k]) {
          this.$message({
            message: "请完善资料",
          });
          return;
        }
        p += `${k}::${this.put[k]}||`;
      }
      if (this.put.head == 'diy' && this.diy_img == '') {
                 this.$message({
            message: "请上传头像",
          });
        return 
      }
      if (this.use_type == "taiwanpassport" || this.use_type == "hkid") {
        if (!this.isChinese(this.put.fn) || !this.isChinese(this.put.ln)) {
          this.$message({
            message: "姓名需要填写中文",
          });
          return;
        }
      }

      await this.get(
        "make_plus",
        { info: p, put: this.put, type: this.use_type },
        "post"
      ).then((e) => {
        this.user.money = e.money;
        this.$store.commit("set_money", parseInt(e.money));
        // this.$refs.task.f5()
        this.$message({
          message: "已提交数据,最晚10秒会显示在右侧任务记录",
        });
      });
    },
    f5() {
      this.get("user").then((e) => {
        this.user = e.user;
        this.mkphoto = e.mkphoto;
        this.log = e.log;
      });
    },
    diy_s1() {
      this.diy_show = true;
      this.put.head = "diy";
    },
    cutDown(event) {
      console.log(event);
      let ths = this;

      let vo = event.file;
      const loading = this.$loading({
        lock: true,
        text: "正在处理图片..",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      removeBackground(vo).then((blob) => {
        // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
        const url = URL.createObjectURL(blob);
        console.log("res url wawa", url);

        var fd = new FormData(); // 我们需要通过dataURItoBlob方法来让 canvas.toDataURL转成biob类型的
        fd.append("blob", blob);

        ths.get("/uplopad", fd, "post").then((e) => {
          ths.put.diy_img = e.r;
          // https://amd.supdadi.xyz/uploads/20240705/1720174735535.png
          loading.close();
           this.$message({
              message: "图片已上传, 如果自动去除背景结果不满意 更换图片即可",
            });
        });
      });
    },
    head_change() {
      let ths = this;
      const files = this.$refs.head_ipt.files;
      if (files.length <= 0) {
        return;
      }
      let vo = files[0];

      removeBackground(vo).then((blob) => {
        // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
        const url = URL.createObjectURL(blob);
        console.log("res url wawa", url);
        ths.put.diy_img = url;
      });
    },
  },
  mounted() {
    this.f5();
  },
  components: {
    //注册
    nav_list,
    foot,
    quk_in,
    task,
    ImgCutter,
  },
};
</script>
<style scoped>
.img_bg {
  min-height: 200px;

  text-align: center;
}
.img_bg img {
  display: block;
  width: 60%;
  height: auto;
  margin: auto;
}
#app {
  width: auto !important;
}
.el-input__inner {
  tab-index: 1;
}
</style>
<style  lang="less" scoped>
.form {
  width: 90%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  font-size: 13px;

  .tit1 {
    font-size: 18px;
    padding: 10px 0;
    color: #235dd1;
    width: 90%;
    margin-bottom: 10px;
  }

  .put {
    flex: 0 0 100%;
  }

  .rrrrrr {
    line-height: 40px;
    width: 90%;
    margin-bottom: 20px;

    p {
      line-height: 20px;
      color: #696969;
    }
  }

  .row {
    display: flex;
    align-items: center;
    min-height: 50px;
    margin-bottom: 20px;

    .lab {
      flex: 0 0 30%;
    }

    .c {
      flex: 0 0 40%;
    }
  }

  .log {
    flex: 0 0 50%;

    .list {
      width: 90%;

      .itm {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.line_box {
  height: 600px;
  width: 80%;

  p {
    color: #696969;
  }
}

.headss {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;
  .headr {
    flex: 0 0 13%;
    cursor: pointer;
    border: 2px solid #fff;
    margin: 1px;
    img {
      width: 80%;
      margin: auto;
      display: block;
    }
    .headnull {
      width: 80%;

      text-align: center;
      font-size: 12px;
      margin: 10px auto;
    }
  }

  .act_head {
    border: 2px solid #f56c6c;
  }
}
</style>
