<template>
  <qk_form use_type="audl_act" :has_back="true" :select_head="true"  :field="field" :req="req" title="澳大利亚-ACT-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn2', title: '坚编号', placeholder: 'A0166519789 不填写自动生成', },
        { k: 'ssn', title: '编号', placeholder: '257843 不填写自动生成', },
        { k: 'ads1', title: ' 地址上', placeholder: 'GEORGETOWN TERRACE U 5 1-5 GOLDMAN ST', },
        { k: 'ads2', title: ' 地址下', placeholder: 'BRUCE ACT 2617', },
      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads1', 'ads2'
      ],


    }
  },
  methods: {
  },



};
</script>
