<template>
  <qk_form use_type="dedl" :field="field"  :select_head="true" :has_back="true" :req="req" title="德国驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号', placeholder: 'A77801PDP53 不填写自动生成', },
         { k: 'state', title: ' 市镇', placeholder: 'Berlinl', },
        { k: 'ads1', title: ' 行政区  ', placeholder: 'Landratsamt', },
        { k: 'ads2', title: ' 县 ', placeholder: 'Musterhausen am See', },
        { k: 'COD', title: ' 国家 ', placeholder: 'tianxiesuozaiguojia', },
       
      ],
      line: '',
      req: [
        'fn', 'ln',   'bor',   'ads1', 'ads2', 'state',  'COD'
      ],


    }
  },
  methods: {
  },



};
</script>
