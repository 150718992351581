<template>
  <qk_form use_type="swe_dl" :has_back="true" :field="field" :req="req" title="瑞典驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号5', placeholder: '660202-3118 不填写自动生成', },
          { k: 'COD', title: '驾照号4d', placeholder: '234567890 不填写自动生成' },
        { k: 'state', title: '地址', placeholder: '如:Vagverket', },


      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state',
      ],


    }
  },
  methods: {
  },



};
</script>
