<template>
  <qk_form use_type="nz_dl" :has_back="true" :field="field" :req="req" title="新西兰-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号', placeholder: 'CA356834 不填写自动生成', },
        { k: 'state', title: '州/省', placeholder: '如 OTAGO', },
        { k: 'ads2', title: '城市', placeholder: '如 QUEENSTOWN', },
        { k: 'ads1', title: '详细地址', placeholder: '如 13-70 ROBINS ROAD', },




      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state', 'ads1', 'ads2'
      ],


    }
  },
  methods: {
  },



};
</script>
