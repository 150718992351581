import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base : '/',
  routes: [
    // 首页




    // {
    //   path: '/seat_search',
    //   name: 'seat_search',
    //   component: require('@/pages/index/seat_search').default,
    // },
    {
      path: '/home',
      name: 'home',
      component: require('@/pages/index/index').default,
    },
    {
        path: '/test',
        name: 'test',
      component: require('@/pages/test').default,
    },
    {
      path: '/user',
      name: 'user',
      component: require('@/pages/user/index').default,
    },
    {
      path: '/slk/dl',
      name: 'slk dr',
      component: require('@/pages/slk/dl').default,
    },
    {
        path: '/de/invoice',
        name: 'uk invoice',
        component: require('@/pages/de/invoice').default,
    },
    {
      path: '/de/de_invoice_tel',
      name: 'uk de_invoice_tel',
      component: require('@/pages/de/de_invoice_tel').default,
    },
    {
      path: '/de/dl',
      name: 'uk passport',
      component: require('@/pages/de/dl').default,
    },
    {
      path: '/rou/dl',
      name: 'rourou',
      component: require('@/pages/rou/dl').default,
    },
    {
      path: '/rou/r1',
      name: 'rourour1',
      component: require('@/pages/rou/r1').default,
    },
    {
      path: '/taiwan/passport',
      name: 'taiwan passport',
      component: require('@/pages/taiwan/passport').default,
    },
    {
      path: '/hk/id',
      name: 'hk id',
      component: require('@/pages/hk/id').default,
    },
    {
      path: '/au/passport',
      name: 'au passport',
      component: require('@/pages/au/passport').default,
    },
    {
      path: '/au/dl_qld',
      name: 'au dl_qld',
      component: require('@/pages/au/dl_qld').default,
    },
    {
        path: '/au/dian_in',
        name: 'au dian_in',
        component: require('@/pages/au/dian_in').default,
    },
    {
      path: '/au/dl_sa',
      name: 'au dl_sa',
      component: require('@/pages/au/dl_sa').default,
    },
    {
      path: '/au/dl_nt',
      name: 'au dl_nt',
      component: require('@/pages/au/dl_nt').default,
    },
    {
      path: '/ch/dl',
      name: 'ch dl',
      component: require('@/pages/ch/dl').default,
    },
    {
      path: '/au/dl_act',
      name: 'au dl_act',
      component: require('@/pages/au/dl_act').default,
    },
    {
      path: '/au/dl_nsw',
      name: 'au dl_nsw',
      component: require('@/pages/au/dl_nsw').default,
    },
    {
      path: '/au/dl_tas',
      name: 'au dl_tas',
      component: require('@/pages/au/dl_tas').default,
    },
    {
      path: '/au/dl_vic',
      name: 'au dl_vic',
      component: require('@/pages/au/dl_vic').default,
    },
    {
      path: '/au/dl_wa',
      name: 'au dl_wa',
      component: require('@/pages/au/dl_wa').default,
    },
    {
      path: '/singapore/dl',
      name: 'singapore dl',
      component: require('@/pages/singapore/dl').default,
    },
    {
      path: '/singapore/passport',
      name: 'singapore passport',
      component: require('@/pages/singapore/passport').default,
    },
    {
      path: '/jp/passport',
      name: 'jp passport',
      component: require('@/pages/jp/passport').default,
    },
    {
      path: '/jp/dl',
      name: 'jp dl',
      component: require('@/pages/jp/dl').default,
    },
    {
      path: '/italy/dl',
      name: 'italy dl',
      component: require('@/pages/italy/dl').default,
    },
    {
      path: '/italy/z1',
      name: 'italy z1',
      component: require('@/pages/italy/z1').default,
    },
    {
      path: '/italy/z2',
      name: 'italy z2',
      component: require('@/pages/italy/z2').default,
    },
    {
      path: '/de/passport',
      name: 'de passport',
      component: require('@/pages/de/passport').default,
    },
    {
      path: '/uk/passport',
      name: 'uk passport',
      component: require('@/pages/uk/passport').default,
    },
    {
      path: '/uk/dl',
      name: 'uk dl',
      component: require('@/pages/uk/dl').default,
    },
    {
      path: '/fr/passport',
      name: 'fr passport',
      component: require('@/pages/fr/passport').default,
    },
    {
      path: '/fr/dl',
      name: 'fr dl',
      component: require('@/pages/fr/dl').default,
    },
    {
      path: '/fr/id',
      name: 'fr id',
      component: require('@/pages/fr/id').default,
    },
    {
      path: '/nz/dl',
      name: 'nz dl',
      component: require('@/pages/nz/dl').default,
    },
    {
      path: '/indonesia/dl',
      name: 'indonesia dl',
      component: require('@/pages/indonesia/dl').default,
    },
    {
      path: '/nz/passport',
      name: 'nz passport',
      component: require('@/pages/nz/passport').default,
    },
    {
      path: '/es/dl',
      name: 'es dl',
      component: require('@/pages/es/dl').default,
    },
    {
      path: '/es/id',
      name: 'es id',
      component: require('@/pages/es/id').default,
    },
    {
        path: '/es/ino_orange',
        name: 'es ino_orange',
        component: require('@/pages/es/ino_orange').default,
    },
    {
        path: '/es/shuidian',
        name: 'es shuidian',
        component: require('@/pages/es/shuidian').default,
    },
    {
        path: '/es/z2',
        name: 'es z2',
        component: require('@/pages/es/z2').default,
    },
    {
      path: '/us/driver',
      name: 'us driver',
      component: require('@/pages/us/driver').default,
    },
    {
      path: '/us/ssn',
      name: 'us ssn',
      component: require('@/pages/us/ssn').default,
    },
    {
      path: '/us/id',
      name: 'usaid',
      component: require('@/pages/us/index').default,
    },
    {
      path: '/us/passport',
      name: 'usaid',
      component: require('@/pages/us/passport').default,
    },
    {
      path: '/us/ak',
      name: 'us ak',
      component: require('@/pages/us/ak').default,
    },
    {
      path: '/us/al',
      name: 'us al',
      component: require('@/pages/us/al').default,
    },
    {
      path: '/us/ar',
      name: 'us ar',
      component: require('@/pages/us/ar').default,
    },
    {
      path: '/us/az',
      name: 'us az',
      component: require('@/pages/us/az').default,
    },
    {
      path: '/us/ca',
      name: 'us ca',
      component: require('@/pages/us/ca').default,
    },
    {
      path: '/us/ct',
      name: 'us ct',
      component: require('@/pages/us/ct').default,
    },
    {
      path: '/us/de',
      name: 'us de',
      component: require('@/pages/us/de').default,
    },
    {
      path: '/us/fl',
      name: 'us fl',
      component: require('@/pages/us/fl').default,
    },


    {
      path: '/can/id',
      name: 'cacaca',
      component: require('@/pages/can/id').default,
    },
    {
      path: '/can/passport',
      name: 'cacaca passport',
      component: require('@/pages/can/passport').default,
    },

    {
      path: '/esp/id',
      name: 'espid',
      component: require('@/pages/esp/id').default,
    },
    {
      path: '/mys/passport',
      name: 'myspassport',
      component: require('@/pages/mys/passport').default,
    },
    {
      path: '/phl/passport',
      name: 'phlpassport',
      component: require('@/pages/phl/passport').default,
    },
    {
      path: '/pol/passport',
      name: 'polpassport',
      component: require('@/pages/pol/passport').default,
    },
    {
      path: '/swe/dl',
      name: 'swe dl',
      component: require('@/pages/swe/dl').default,
    },
    {
      path: '/belg/id',
      name: 'belg_id',
      component: require('@/pages/belg/id').default,
    },
    {
      path: '/nld/id',
      name: 'nldd',
      component: require('@/pages/nld/id').default,
    },
    {
      path: '/nld/z1',
      name: 'nlddz',
      component: require('@/pages/nld/z1').default,
    },
    {
      path: '/nld/passport',
      name: 'nlddpassport',
      component: require('@/pages/nld/passport').default,
    },

    {
      path: '/us/invoice',
      name: 'invoice',
      component: require('@/pages/uszd/invoice').default,
    },
    {
      path: '/jump/to',
      name: 'jump-to',
      component: require('@/pages/jump/to').default,
    },



    // 个人中心
    {
      path: '/personal',
      name: 'personal',
      component: require('@/pages/index/personal').default,
    },


    {
      path: '/',
      name: 'login',
      component: require('@/pages/index/login').default,
    },
    {
      path: '/rkb',
      name: 'rkb',
      component: require('@/pages/index/rkb').default,
    },
    {
      path: '/reg',
      name: 'reg',
      component: require('@/pages/index/reg').default,
    },
    {
      path: '/edit_login',
      name: 'edit_login',
      component: require('@/pages/index/edit_login').default,
    },


  ]
})
