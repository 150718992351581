<template>
  <div class="login">

    <div class="t2">

      <div class="inp1">
            <span> 邀请码 </span>
            <el-input class="inp2" v-model="put.invite_code" placeholder="请输入邀请码"></el-input>
          </div>


        <div class="inp1">
          <span> 账号 </span>
          <el-input class="inp2" v-model="put.account" placeholder="请输入您的账号"></el-input>
        </div>

      <div class="inp1">
        <span> 邮箱 </span>
        <el-input class="inp2" v-model="put.email" placeholder="请输入您的邮箱"></el-input>
      </div>
      <div class="inp1">
          <span> 密码 </span>
          <el-input class="inp2" placeholder="请输入您的密码" v-model="put.pwd" show-password></el-input>
        </div>
      <div class="inp1">
        <span>  确认密码 </span>
        <el-input class="inp2" placeholder="确认密码" v-model="put.rpwd" show-password></el-input>
      </div>

      <div class="btn">
        <el-button class="btn1" type="primary" @click="sbmt">注册 </el-button>
        <el-button class="btn1"   @click="jump('/')">返回登录 </el-button>
      </div>



    </div>


  </div>
</template>

<script>
import page_tc from '../../components/page_tc.vue';
export default {
  components: { page_tc },
    name:"login",
    data() {
        return {
          put : {
            email: '',
            pwd: '',
            rpwd: '',
            openid : '',
            invite_code : '',
            account : '',
          },
          checked: true,

        }
    },
    methods:{
      show_page(id) {
        // alert(id)

        this.$refs.pag_tc.f5(id)
      },
      sbmt() {


        var eemailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;

        if (!eemailReg.test(this.put.email)) {
          this.$message({
            type: 'success',
            message:  '邮箱格式错误'
          });
          return
        }

        if (this.put.pwd.length < 6) {
          this.$message({

            message: '强度过低'
          });
          return;
        }
        if (this.put.pwd != this.put.rpwd) {
          this.$message({

            message:  '两次密码不一致'
          });
          return;
        }


        this.get("/register", this.put, 'post').then((e) => {

          this.cache('info', e.info);
          this.cache('token', e.token);
          this.$message({
            type: 'success',
            message: '注册成功'
          });

          this.jump('/')



        });
      }
    }
}
</script>

<style scoped>
.login{
    width: 1100px;
    height: 100vh;

      background-repeat: no-repeat;
    margin: 0 auto;
    z-index: 4;
    display: flex;
}
.login .t1{
    flex: 0 0 50%;
}
.login .t2{
    margin: 0 auto;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
}
.login .inp1{
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
}
.login .inp1 span{
    margin-left: 5%;
    margin-bottom: 3%;
    color: rgb(33, 37, 41);
    font-family: Noto Sans SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0%;
}
.login .inp1 .inp2{
    width: 280px;
    height: 5vh;
    background: rgb(249, 250, 254);
    border-radius: 8px;
}
.login .btn1{
    width: 140px;
    height: 5vh;

    border-radius: 8px;

}
.login .remark{
    display: flex;
    margin-top: 5%;
}
.login .remark span{
    font-family: Noto Sans SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}
.login .remark2{
    display: flex;
    margin: 0 auto;
    margin-top: 15%;
}
.login .remark2 span{
    font-family: Noto Sans SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;

}
.login .t3 {
    margin-top: 3%;
    margin-right: 4%;
}
</style>
