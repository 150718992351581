<template>
    <qk_form use_type="us_ssn" :has_back="true" :field="field" :req="req" title="美国SSN">





    </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
    components: { qk_form },
    name: "index",
    data() {
        return {
            field: [
                 { k: 'ssn', title: 'ssn', placeholder: '  不填写自动生成', },
            ],
            line: '',
            req: [
                'fn', 'ln',  
            ],


        }
    },
    methods: {
    },



};
</script>
