<template>
  <qk_form use_type="audl_nt" :has_back="true" :select_head="true" :field="field" :req="req" title="澳大利亚-NT-驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [

        { k: 'ssn', title: '编号', placeholder: '6647379 不填写自动生成', },
        { k: 'ads1', title: ' 地址上', placeholder: '30 MARADU CRES', },
        { k: 'ads2', title: ' 地址下', placeholder: 'WANNEROO   WA   6065', },
      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads1', 'ads2'
      ],


    }
  },
  methods: {
  },



};
</script>
