<template>
    <qk_form use_type="ch_dl_base" :field="field" :select_head="true"  :has_back="true" :req="req" title="瑞士驾照">





    </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
    components: { qk_form },
    name: "index",
    data() {
        return {
            field: [
                { k: 'ssn', title: '驾照号', placeholder: '07920307 不填写自动生成', },
                { k: 'state', title: '出生地', placeholder: '如 Bern', }, 
            ],
            line: '',
            req: [
                'fn', 'ln',   'bor', 'state', 
            ],


        }
    },
    methods: {
    },



};
</script>
