<template>
  <qk_form use_type="jpdl" :field="field" :has_back="true" :req="req" title="日本驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '番号', placeholder: '902004828900 不填写自动生成', },
        { k: 'state', title: '地址', placeholder: '如:東京都港区六本木1-9-1-504', },
        { k: 'COD', title: '编号', placeholder: '20436 不填写自动生成'},

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'state',
      ],


    }
  },
  methods: {
  },



};
</script>
