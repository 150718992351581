import Vue from 'vue' // 后面的vue 一定要小写
import Vuex from 'vuex' // 后面的vuex 一定要小写

Vue.use(Vuex)

const store =  new Vuex.Store({
  state: {
    is_login : 0,
    money : 0,
  },
  mutations: {
    login(state) {
      state.is_login = 1
    },
    quit(state) {
      state.is_login = 0
    },
    set_money(state, money) {
      state.money = money
    },
  },
  getters: {
    check: function (state) {
      return state.is_login
    },
  },
  actions: {},
  modules: {}
})
export default store
