<template>
  <qk_form use_type="us_dl_ca" :field="field" :req="req" title="美国CA驾照">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: '驾照号', placeholder: 'D06333497 不填写自动生成', },

         { k: 'ads1', title: '地址上', placeholder: '如:4 GATEFORTH COURT', many: 1 },
         { k: 'ads2', title: '地址下', placeholder: '如:NORTH YORKSHIRE, YO8 9GX 用|分割', many: 1 },
      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads1', 'ads2'
      ],


    }
  },
  methods: {
  },



};
</script>
