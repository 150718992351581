<template>
  <div class="t1">
    <nav_list></nav_list>
    <page_cont>
      <div class="header">
        个人中心
      </div>

      <div class="form">
        <div class="row">

        </div>
      </div>
    </page_cont>


  </div>
</template>

<script>
import nav_list from './nav_list.vue';
export default {
  name: "index",
  data() {
    return {
      put: {
        fname: '',
        lname: '',
      }, options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  methods: {


  },
  mounted() {

  },
  components: {  //注册
    nav_list,
  }

};
</script>

<style  lang="less" scoped>
.header{
  height: 100px;
  padding: 10px;
}
</style>
