<template>
  <div class=" ">
    <div class="form">
      <div class="put">
        <div class="tit1">
          {{ title }}
        </div>

        <div class="row">
          <div class="lab reqd">Frist name 名字:</div>
          <div class="ipt">
            <el-input
              placeholder="包含中间名 用空格格开"
              suffix-icon="el-icon-date"
              v-model="put.fn"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="lab reqd">Last name 姓氏:</div>
          <div class="ipt">
            <el-input
              placeholder=" Last name"
              suffix-icon="el-icon-date"
              v-model="put.ln"
            ></el-input>
          </div>
        </div>
 

        
        <div class="row">
          <div class="lab reqd">银行账户:</div>
          <div class="ipt">
            <el-input
              placeholder=" 如7838-2886-0227-4015 不填写自动生成"
             
              v-model="put.idcard"
            ></el-input>
          </div>
        </div>

        <div class="row">
          <div class="lab reqd">地址1:</div>
          <div class="ipt">
            <el-input
              placeholder=" 如 RBLA BADAL,47-49 LOC-2 E.ESQ"
             
              v-model="put.ads1"
            ></el-input>
          </div>
        </div>
  
        <div class="row">
          <div class="lab reqd">地址2 :</div>
          <div class="ipt">
            <el-input
              placeholder="如: 08014 BARCELONA "
             
              v-model="put.ads2"
            ></el-input>
          </div>
        </div>

        <div class="row">
          <div class="lab reqd">账单日期:</div>
          <div class="ipt">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="put.iis"
              type="date"
              placeholder="日期"
            >
            </el-date-picker>
          </div>
        </div>
         
 

        <div>
          <el-button type="success" @click="mk"
            >生成 - {{ mkphoto }}<i class="el-icon-coin"></i
          ></el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span> 当前余额 : <i class="el-icon-coin"></i>{{ user.money }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nav_list from "@/pages/index/nav_list.vue";
import foot from "@/pages/index/foot.vue";
import task from "@/components/task.vue";

export default {
  name: "qk_form",

  data() {
    return {
      title: "西班牙-水电账单",
      use_type: "es_dian",
      user: {},
      full: 1,
      items: [
        "Argan oil Hair & Locks Butter",
        "Mix and Match Soap",
        "Hair Style Finishing Sprayk",
      ],

      put: {
        fn: "",
        ln: "",
        name: "",
        idcard: "",
        bor: "",
        sex: "M",
        COD: "",
        PDN: "",
        RT: "",
        iis: "",
        ssn: "",
        ssn2: "",
        light: "y",
        state: "",
        ads1: "",
        ads2: "",
        ads3: "",
        rate: "n",
        mk_back: false,
        get_conf_state: "USAID",
        items: [],
        tpl: 1,
        card: "Debit",
        card_type: "Mastercard",
        bank_last: "",
      },
      req: ["fn", "ln",  "ads1", "ads2", "iis"],
      value: "",
      mkphoto: -1,
      log: [],
      out: "",
      activeName: "line",
      line: "",
    };
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // 生成 [0, i] 之间的随机索引
        [array[i], array[j]] = [array[j], array[i]]; // 交换当前位置与随机位置上的元素
      }
    },
    rd_txt() {
      let txts = this.items.concat();
      this.shuffle(txts);
      console.log(txts);
      for (let i = 0; i < this.put.items.length; i++) {
        this.put.items[i].txt = txts[i];
      }
    },
    rd_num() {
      for (let i = 0; i < this.put.items.length; i++) {
        this.put.items[i].num = this.getRandomInt(10, 50);
      }
    },
    rd_price() {
      for (let i = 0; i < this.put.items.length; i++) {
        this.put.items[i].price = parseFloat(
          this.getRandomInt(35, 100)
        ).toFixed(2);
      }
    },
    priceFormat(i) {
      this.put.items[i].price =
        this.put.items[i].price.match(/^\d*(\.?\d{0,2})/g)[0] || "0";
      if (isNaN(this.put.items[i].price)) {
        this.put.items[i].price = 1;
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = this.put.items[i].price + "";
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.put.items[i].price.length >= 2
      ) {
        this.put.items[i].price = this.put.items[i].price.replace(/0/, "");
      }
    },
    async checkname() {
      if (!this.put.fn) {
        return;
      }
      await this.get(`checkname/${this.put.fn}`).then((e) => {
        if (e.sex == "Male") {
          this.put.sex = "M";
        } else if (e.sex == "Female") {
          this.put.sex = "F";
        } else {
          // this.$message({
          //   type: 'success',
          //   message: '性别获取失败'
          // });
        }
      });
    },
    async qk(out, batch = false, tm = "") {
      if (!batch) {
        for (let k in this.put) {
          if (k in out) {
            this.put[k] = out[k];
          }
        }
        await this.checkname();
      } else {
        this.put.batch_sn = tm;

        for (const ot of out) {
          for (let k in this.put) {
            if (k in ot) {
              this.put[k] = ot[k];
            }
          }
          await this.checkname();
          await this.mk();
        }

        this.get("batch_start", { batch_sn: this.put.batch_sn }, "post").then(
          (e) => {
            this.$message({
              message: "批量任务已开始",
            });
          }
        );

        this.put.batch_sn = "";
      }
    },
    isValidDate(date) {
      var regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
      return regex.test(date);
    },
    isTel(date) {
      var regex = /^[0-9]{10}$/;
      return regex.test(date);
    },
    async mk() {
      this.out = "";
      let p = "";
    
 

      if (!this.isValidDate(this.put.iis)) {
        this.$message({
          message: "日期格式错误",
        });
        return;
      }

      for (let k in this.put) {
        if (this.req.indexOf(k) !== -1 && !this.put[k]) {
          this.$message({
            message: "请完善资料",
          });
          return;
        }
        p += `${k}::${this.put[k]}||`;
      }

      await this.get(
        "make_plus",
        { info: p, put: this.put, type: this.use_type },
        "post"
      ).then((e) => {
        this.user.money = e.money;
        this.$store.commit("set_money", parseInt(e.money));
        // this.$refs.task.f5();
        this.$message({
          message: "已提交数据,最晚10秒会显示在右侧任务记录",
        });
      });
    },
    f5() {
      this.get("user").then((e) => {
        this.user = e.user;
        this.mkphoto = e.mkphoto;
        this.log = e.log;
      });
    },
  },
  mounted() {
    this.f5();
    for (let i = 0; i < this.full; i++) {
      this.put.items.push({
        txt: "",
        num: 1,
        price: 1,
      });
    }
  },
  components: {
    //注册
    nav_list,
    foot,
    task,
  },
};
</script> 

<style scoped>
#app {
  width: auto !important;
}
.el-input__inner {
  tab-index: 1;
}
</style>
<style  lang="less" scoped>
.form {
  width: 90%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  font-size: 13px;

  .tit1 {
    font-size: 18px;
    padding: 10px 0;
    color: #235dd1;
    width: 90%;
    margin-bottom: 10px;
  }

  .put {
    flex: 0 0 100%;
  }

  .rrrrrr {
    line-height: 40px;
    width: 90%;
    margin-bottom: 20px;

    p {
      line-height: 20px;
      color: #696969;
    }
  }

  .row {
    display: flex;
    align-items: center;
    min-height: 50px;
    margin-bottom: 20px;

    .lab {
      flex: 0 0 30%;
    }

    .c {
      flex: 0 0 40%;
    }
  }

  .log {
    flex: 0 0 50%;

    .list {
      width: 90%;

      .itm {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.line_box {
  height: 600px;
  width: 80%;

  p {
    color: #696969;
  }
}

.headss {
  display: flex;
  flex-wrap: wrap;
  .headr {
    flex: 0 0 13%;
    cursor: pointer;
    border: 2px solid #fff;
    margin: 1px;
    img {
      width: 80%;
      margin: auto;
      display: block;
    }
    .headnull {
      width: 80%;

      text-align: center;
      font-size: 12px;
      margin: 10px auto;
    }
  }

  .act_head {
    border: 2px solid #f56c6c;
  }
}

.tpls {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  .tpl {
    margin: 1%;
    flex: 0 0 19%;
    position: relative;
    height: 40px;
    border: 2px solid #fff;
    box-shadow: 4px 4px 4px #bbb;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .act {
    box-shadow: 4px 4px 4px #fed700;
    border: 2px solid #fed700;
  }
}

.tips {
  color: #696969;
  padding: 4px;
}
</style>

