<template>
  <qk_form use_type="frid_dl" :has_back="true" :field="field" :req="req" title="法国id">





  </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
  components: { qk_form },
  name: "index",
  data() {
    return {
      field: [
        { k: 'ssn', title: 'ID号', placeholder: '130634358863 不填写自动生成', },
        { k: 'state', title: '出生地（这里填地名或者城市名）', placeholder: '如:BRETAGIE', },
         { k: 'ads1', title: '地址', placeholder: '如:2 RUE JEAN MONNET BP 60215|35602 REDON CEDEX 用|分割换行.两行最好', many : 1},
         { k: 'ads2', title: '省份', placeholder: '如: ILLE-ET-VILAINE', },

      ],
      line: '',
      req: [
        'fn', 'ln', 'bor', 'ads2', 'ads1', 'state'
      ],


    }
  },
  methods: {
  },



};
</script>
