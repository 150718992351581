
 export const  STATE_CONF = {
  'AL': 'alabama',
  'AK': 'alaska',
  'AZ': 'arizona',
  'AR': 'arkansas',
  'CA': 'california',
  'CO': 'colorado',
  'CT': 'connecticut',
   'DC': 'DC-District of Columbia',
  'DE': 'delaware',
  'FL': 'florida',
  'GA': 'georgia',
  'HI': 'hawaii',
  'ID': 'idaho',
  'IL': 'illinois',
  'IN': 'indiana',
  'IA': 'iowa',
  'KS': 'kansas',
  'KY': 'kentucky',
  'LA': 'louisiana',
  'ME': 'maine',
  'MD': 'maryland',
  'MA': 'massachusetts',
  'MI': 'michigan',
  'MN': 'minnesota',
  'MS': 'mississippi',
  'MO': 'missouri',
  'MT': 'montana',
  'NE': 'nebraska',
  'NV': 'nevada',
  'NH': 'newhampshire',
  'NJ': 'newjersey',
  'NM': 'newmexico',
  'NY': 'newyorkstate',
  'NC': 'northcarolina',
  'ND': 'northdakata',
  'OH': 'ohio',
  'OK': 'oklahoma',
  'OR': 'oregon',
  'PA': 'pennsylvania',
  'PR': 'puertorico',
  'RI': 'rhodeisland',
  'SC': 'southcarolina',
  'SD': 'southdakota',
  'TN': 'tennessee',
  'TX': 'texas',
  'UT': 'utah',
  'VT': 'vermont',
  'VA': 'virginia',
  'WA': 'washington',
  'WV': 'westvirginia',
  'WI': 'wisconsin',
  'WY': 'wyoming',


}
export let stat = []
for (let key in STATE_CONF) {
  stat.push({
    value : key,
    label: key
  })
}
export default {stat, STATE_CONF}
