<template>
    <qk_form use_type="canadaid" :field="field" :has_back="true" :req="req" title="加拿大ID">





    </qk_form>
</template>

<script>
import qk_form from '../../components/qk_form.vue';
export default {
    components: { qk_form },
    name: "index",
    data() {
        return {
            field: [
                { k: 'ssn', title: '卡号', placeholder: '6582-7006 不填写自动生成', },
             
            ],
            line: '',
            req: [
                'fn', 'ln', 'bor', 
            ],


        }
    },
    methods: {
    },



};
</script>
