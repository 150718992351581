<template>
  <div>
    <div class="bar">
     
      <div>
        <span>
          <div class="itmaa" v-if="!$store.state.is_login" style="flex:0 0 25%">
            <span @click="jump('/login')"> <i class="el-icon-user"></i> {{ this.$lang('50115') }}</span>
            |
            <span @click="jump('/reg')"> <i class="el-icon-edit-outline"></i> {{ this.$lang('50118') }}</span>
          </div>
          <div v-else class="itmaa">
            <div class="bdaa  itmaa" >
              <span class="dzdzd"> {{ info.account || info.mail }}</span>  
             
              <div class="bag">
                <el-badge :value="$store.state.money" type="warning">
                  <i class="el-icon-shopping-bag-2"></i>
                </el-badge>
              </div>
            </div>
            <div  class="bdaa">
              
         
             
            
            </div>
            <div class="bdaa" @click="quit"> <i class="el-icon-close"></i> 退出登录 </div>


          </div>



        </span>
      </div>

    </div>

    



    <div class="m1"  >

      <el-menu :default-active="activeIndex" class="el-menu-demo"
        mode="horizontal">

        <el-submenu index="1">
          <template slot="title"> 美国 America</template>
          <el-menu-item index="1-1" @click="jump('/us/passport')">护照 </el-menu-item>
          <el-menu-item index="1-2" @click="jump('/us/id')">ID卡</el-menu-item>
          <el-menu-item index="1-3" @click="jump('/us/driver')"> 驾照</el-menu-item>
          <el-menu-item index="1-4" @click="jump('/us/ssn')"> SSN</el-menu-item>
          <el-menu-item index="1-4" @click="jump('/us/invoice')"> 账单</el-menu-item>


        </el-submenu>
        <el-submenu index="2">
          <template slot="title"> 英国 Britain</template>
          <el-menu-item index="2-1" @click="jump('/uk/passport')">护照</el-menu-item>
          <el-menu-item index="2-2" @click="jump('/uk/dl')">驾照</el-menu-item>

        </el-submenu>
        <el-submenu index="3">
          <template slot="title"> 德国 Germany</template>
          <el-menu-item index="3-1" @click="jump('/de/dl')">驾照</el-menu-item>
          <el-menu-item index="3-1" @click="jump('/de/passport')">护照</el-menu-item>

        </el-submenu>
        <el-submenu index="4">
          <template slot="title"> 澳大利亚 Australia</template>
          <el-menu-item index="4-1" @click="jump('/au/passport')">护照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_qld')">QLD-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_nsw')">NSW-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_tas')">TAS-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_vic')">VIC-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_wa')">WA-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_act')">ACT-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_sa')">SA-驾照</el-menu-item>
          <el-menu-item index="4-2" @click="jump('/au/dl_nt')">NT-驾照</el-menu-item>

        </el-submenu>
        <el-submenu index="5">
          <template slot="title"> 台湾 TaiWan</template>
          <el-menu-item index="5-1" @click="jump('/taiwan/passport')">护照</el-menu-item>

        </el-submenu>
        <el-submenu index="6">
          <template slot="title"> 法国 France</template>
          <el-menu-item index="6-1" @click="jump('/fr/passport')">护照</el-menu-item>
          <el-menu-item index="6-2" @click="jump('/fr/dl')">驾照</el-menu-item>
          <el-menu-item index="6-3" @click="jump('/fr/id')">ID</el-menu-item>

        </el-submenu>
        <el-submenu index="7">
          <template slot="title"> 新加坡 Singapore</template>
          <el-menu-item index="7-1" @click="jump('/singapore/passport')">护照</el-menu-item>
          <el-menu-item index="7-2" @click="jump('/singapore/dl')">驾照</el-menu-item>

        </el-submenu>
        <el-submenu index="8">
          <template slot="title"> 意大利 Italy</template>
          <el-menu-item index="8-1" @click="jump('/italy/dl')">驾照</el-menu-item>

        </el-submenu>

                <el-submenu index="9">
            <template slot="title"> 香港 HongKong</template>
            <el-menu-item index="9-1" @click="jump('/hk/id')">ID</el-menu-item>

          </el-submenu>

                <el-submenu index="10">
            <template slot="title"> 日本 Japan</template>
            <el-menu-item index="9-1" @click="jump('/jp/passport')">护照</el-menu-item>
            <el-menu-item index="9-2" @click="jump('/jp/dl')">驾照</el-menu-item>

          </el-submenu>


 
          <el-submenu index="12">
            <template slot="title"> 马来西亚 MYS</template>
            <el-menu-item index="12-1" @click="jump('/mys/passport')">护照</el-menu-item>

          </el-submenu>
          <el-submenu index="13">
            <template slot="title"> 菲律宾 PHL</template>
            <el-menu-item index="13-1" @click="jump('/phl/passport')">护照</el-menu-item>

          </el-submenu>
          <el-submenu index="14">
            <template slot="title"> 波兰 POL</template>
            <el-menu-item index="14-1" @click="jump('/pol/passport')">护照</el-menu-item>

          </el-submenu>
          <el-submenu index="15">
            <template slot="title"> 瑞典 SWE</template>
            <el-menu-item index="15-1" @click="jump('/swe/dl')">驾照</el-menu-item>

          </el-submenu>
          <el-submenu index="16">
            <template slot="title"> 比利时 BELG </template>
            <el-menu-item index="16-1" @click="jump('/belg/id')">ID</el-menu-item>

          </el-submenu>
          <el-submenu index="17">
            <template slot="title"> 荷兰 NLD </template>
            <el-menu-item index="17-1" @click="jump('/nld/id')">ID</el-menu-item>
            <el-menu-item index="17-2" @click="jump('/nld/passport')">护照</el-menu-item>

          </el-submenu>
          <el-submenu index="18">
            <template slot="title"> 瑞士 CH </template>
            <el-menu-item index="18-1" @click="jump('/ch/dl')">驾照</el-menu-item>
          </el-submenu>
          <el-submenu index="19">
            <template slot="title"> 斯里兰卡 LK </template>
            <el-menu-item index="19-1" @click="jump('/slk/dl')">驾照</el-menu-item>
          </el-submenu>
          <el-submenu index="20">
            <template slot="title"> 罗马尼亚 ROU </template>
            <el-menu-item index="20-1" @click="jump('/rou/dl')">驾照</el-menu-item>
          </el-submenu>
          <el-submenu index="21">
            <template slot="title"> 西班牙 ES </template>
            <el-menu-item index="21-1" @click="jump('/es/dl')">驾照</el-menu-item>
            <el-menu-item index="21-1" @click="jump('/es/id')">ID</el-menu-item>
          </el-submenu>
          <el-submenu index="22">
            <template slot="title"> 加拿大 CAN </template>
            <el-menu-item index="22-1" @click="jump('/can/id')">ID</el-menu-item>
            <el-menu-item index="22-2" @click="jump('/can/passport')">护照</el-menu-item>
          </el-submenu>
          <el-submenu index="23">
            <template slot="title"> 新西兰 NZ </template>
            <el-menu-item index="23-1" @click="jump('/nz/dl')">驾照</el-menu-item>
            <el-menu-item index="23-2" @click="jump('/nz/passport')">护照</el-menu-item>
          </el-submenu>
          <el-submenu index="24">
            <template slot="title"> 印尼 indonesia </template>
            <el-menu-item index="24-1" @click="jump('/indonesia/dl')">驾照</el-menu-item>
            <!-- <el-menu-item index="23-2" @click="jump('/nz/passport')">护照</el-menu-item> -->
          </el-submenu>

        <!-- <el-submenu v-for="(vo, i) in sss" :index="6 + i" :key="i">
          <template slot="title"> {{ vo }}</template>
          <el-menu-item :index="`${i + 6}-1`">建设中</el-menu-item>

        </el-submenu> -->

      </el-menu>



    </div>
  </div>
</template>

<script>

let sss = '韩国 Australia、日本 Australia、菲律宾 Philippines、越南 Vietnam、老挝 Laos'.split('、')
// 、缅甸、泰国、马来西亚、文莱、新加坡、印度、巴基斯坦、约旦、以色列、巴勒斯坦、巴林、卡塔尔、阿曼、土耳其'.split('、')

export default {
  name: 'nav_list',
  props:
  {
    index: {
      default: 0,
      type: Number,
    },
    show_nav: {
      default: 1,
      type: Number,
    }
  },

  data() {
    return {
      activeIndex: 0,
      info: {},
      lang: '',
      show: true,
      sss: sss,
      code: '',

    }
  },
  methods: {
    set_lang(v) {

      this.lang = v
      this.show = true
      this.cache('lang_config', v)
      this.cache('set_lang', 1)

      this.$forceUpdate();
      location.reload();

    },
    jump33() {
      this.$message({
        type: 'error',
        message: '权限不足'
      });
    },
    quit() {
      this.$message({
        type: 'success',
        message: 'ok'
      });

      this.cache('info', '');
      this.cache('token', '');
      this.$store.commit('quit')
      this.jump('/', {}, true)

    },
    langconf() {
      this.cache('set_lang', 1)
      this.cache('lang_config', this.lang);
      this.$forceUpdate();
      location.reload();
    },
    initialValue() {
      this.lang = this.cache('lang_config')
    },
    refresh_code() {
      this.get("/refresh_code", {}, 'get', false).then((e) => {


        this.info.code = e.code
        this.cache('info', info)
      });
    },
  },
  mounted() {
    if (this.cache('token')) {
      this.$store.commit('login')
      // this.info = this.cache('info')

      this.get("/user", {}, 'get', false).then((e) => {
        
        e.user['code'] = e.code
        this.info = e.user
        console.log('dd',this.info)
        this.$store.commit('set_money', parseInt(e.user.money))
      });
    }
    if ('lang' in this.$route.query) {
      if (!this.cache('set_lang')) {
        this.cache('lang_config', this.$route.query.lang)
        this.cache('set_lang', 0)
      }

    }

    this.initialValue()
  },
}
</script>

<style scoped>
.lang_itm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lang_img {
  width: 25px;
  height: 15px;
}

.m1 {
  box-shadow: 0px 15px 20px -16px rgba(0, 0, 0, 0.25);

  background: #fed700;
  display: flex;
  min-height: 40px;
  align-items: center;
  margin: auto;
  /* margin-left: -10vw; */
  /* justify-content: center; */
  border-radius: 5px;
}

.logo {
  flex: 0 0 15%;
}

.logo .logo_div {
  text-align: right;

}

.logo img {
  width: 320px;
  /* height: 103px; */
}

.nav {
  flex: 0 0 80%;
  display: flex;
  margin: 0 auto;
  /* margin-top: 2%; */
  align-items: center;
  justify-content: space-evenly;
  min-height: 120px;
}

.nav_t1 {
  /* flex: 0 0 15%; */
  /* margin: 0 20px; */
  margin-left: 3%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  cursor: pointer;
  /* color: #1c5098; */
  display: flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;

}

.nav_act {
  font-weight: bold;
}

.nav .nav_t1 a {
  text-decoration-line: none;

}

.nav .nav_t1 span {}

.nav .nav_t1 .iconimg {


  width: 16px;
  margin-right: 4px;

}

.nav_sele {
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.langnav {
  /* position:fixed;
  top: 5%;
  right: 2%; */
}

.zk {
  display: flex;
}

.zk img {
  width: 34px;
  height: 20px;
  border: 1px solid #6d6d6d;

}

.zk .zkitm {
  margin-right: 2px;
  font-size: 12px;
  text-align: center;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.bag {
  font-size: 20px;
    color: #6d6d6d;
}

.bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

}

.itmaa {
  margin-left: 3%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  cursor: pointer;
  /* color: #1c5098; */
  display: flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;
}

.itmaa .bdaa {
  border-right: 1px solid #eee;
  min-width: 100px;
  padding: 0 30px;
  text-align: center;
  color: #6d6d6d;
}

.itmaa .bdaa:last-child {
  border-right: none;

}

.srearch {
  display: flex;
  margin: 10px 5px;
  align-items: center;
  justify-content: space-between;
}

.srewa_input {
  flex: 0 0 60%;
  display: flex;
  justify-content: flex-end
}
.srewa_input_opt {
  flex: 0 0 90%;
}
.aaazz {
 flex: 0 0 6%;
 height:40px
}
</style>
