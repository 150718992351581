<template>
  <div class="login">

    <div class="t2">
      <div class="inp1">
        <span> 账号 </span>
        <el-input class="inp2" v-model="put.account" placeholder="请输入您的账号"></el-input>
      </div>

      <div class="inp1">
        <span> 密码 </span>
        <el-input class="inp2" placeholder="请输入您的密码" v-model="put.pwd" show-password></el-input>
      </div>
      <div class="btn">
        <el-button class="btn1" type="primary" @click="sbmt"> 登录 </el-button>
      </div>







      <div class="remark2">
        <span style="color:rgb(250 116 21);" @click="jump('/reg')"> 注册</span> &nbsp; &nbsp;&nbsp;
        <span style="color:rgb(250 116 21);" @click="jump('/rkb')"> 找回密码 </span>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      put: {
        account: '',
        pwd: '',
      },
      show: false,
      checked: true,

    }
  },
  mounted() {
    if (this.cache('token')) {
      this.jump('/home')
    }
  },
  methods: {


    sbmt() {
      this.get("/login", this.put, 'post').then((e) => {
        this.cache('info', e.info);
        this.cache('token', e.token);
        this.$message({
          type: 'success',
          message: '登录成功'
        });
        this.$store.commit('login')
        this.jump('/home')
      });
    }
  }
}
</script>

<style scoped>
.wxbox {
  text-align: center;
  display: flex;
  justify-content: center;
}

.tcwx {
  width: 100%;
  text-align: center;
}

.tcqrcode {
  margin: auto;
}

.qrtxt {
  color: green;
  font-size: 16px;
  line-height: 30px;
}

.login {

  width: 1100px;
  height: 100vh;

  background-repeat: no-repeat;
  margin: 0 auto;
  z-index: 4;
  display: flex;
  margin-top: 5%;
}

.txtmid {
  line-height: 20px;
}

.login .t1 {
  flex: 0 0 50%;
}

.login .t2 {
  margin: 0 auto;
  margin-top: 7%;
  display: flex;
  flex-direction: column;
}

.login .inp1 {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
}

.login .inp1 span {
  margin-left: 5%;
  margin-bottom: 5%;
  color: rgb(33, 37, 41);
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0%;
}

.login .inp1 .inp2 {
  width: 280px;
  height: 5vh;
  background: rgb(249, 250, 254);
  border-radius: 8px;
}

.login .btn1 {
  width: 280px;
  height: 5vh;
  background: rgb(4, 100, 221);
  border-radius: 8px;

}

.login .remark {
  display: flex;
  margin-top: 5%;
}

.login .remark span {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0%;
  text-align: left;
}

.login .remark2 {
  display: flex;
  margin: 0 auto;
  margin-top: 15%;
}

.login .remark2 span {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0%;

}

.login .t3 {
  margin-top: 3%;
  margin-right: 4%;
}</style>
