<template>
  <div class="t1">

dd
  </div>
</template>

<script>

export default {
  name: "bus_list",


  data() {
    return {

      txt: '',
    };
  },
  mounted(e) {
    document.title = this.web_tit;
    this.jump(this.cache('jump_to'), {}, 2)
  },

  methods: {

  }
}
</script>
