<template>
  <div class="t1">
 


    <div class="contt">

      <div class="form">
        <div class="put">
          <div class="tit1">
           美国驾照 
          </div>

         

      <div class="row"  >
              <div class="lab reqd"> 驾照所在州 </div>
              <div class="ipt">
                <el-select filterable v-model="put.state" placeholder="请选择" @change="change_zhou">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>




                   <div class="row">
                <div class="lab reqd">
                 地址:
                </div>
                <div class="ipt">
                  <el-input placeholder="如: 1337 UNIVERSITY AVE APT 4|BERKELEY CA 94702-1763    用|分割来区分换行" type = "textarea" :rows="6" v-model="put.ads1"></el-input>
                </div>

      </div>

              <div class="row">
              <div class="lab reqd">
               Frist name 名字:
              </div>
              <div class="ipt">
                <el-input placeholder="包含中间名 用空格格开"  @input="checkname" suffix-icon="el-icon-date" v-model="put.fn"></el-input>
              </div>

            </div>

            <div class="row">
              <div class="lab reqd">
                Last name 姓氏:
              </div>
              <div class="ipt">
                <el-input placeholder=" Last name" suffix-icon="el-icon-date" v-model="put.ln"></el-input>
              </div>

            </div>

          <div class="row" v-if="use_type !== 'hkid'">
            <div class="lab">
              Issued On 签发日:
            </div>
            <div class="ipt">

              <el-date-picker value-format="yyyy-MM-dd" v-model="put.iis" type="date" placeholder="Issued On 不填写自动生成">
              </el-date-picker>

            </div>

          </div>
          <div class="row">
            <div class="lab reqd">
              性别:
            </div>
            <div class="ipt">
              <el-radio v-model="put.sex" label="M">男</el-radio>
              <el-radio v-model="put.sex" label="F">女</el-radio>
            </div>

          </div>
          <div class="row">
            <div class="lab reqd">
              生日:
            </div>
            <div class="ipt">

              <el-date-picker value-format="yyyy-MM-dd" v-model="put.bor" type="date" placeholder="选择生日">
              </el-date-picker>

            </div>

          </div>

            <div class="row"   >
              <div class="lab " >
               生成背面:
              </div>
              <div class="ipt">

                 <el-switch
    v-model="put.mk_back"
    active-text="生成(费用+1)"
    inactive-text="不生成">
  </el-switch>
              </div>

            </div>


          <!-- <slot/> -->





            <el-button type="success" @click="mk">生成 - {{ mkphoto }}<i class="el-icon-coin"></i></el-button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span> 当前余额 : <i class="el-icon-coin"></i>{{ user.money }}</span>








        </div>
 


      </div>

    </div>
    <foot></foot>
  </div>
</template>

<script>
import nav_list from '@/pages/index/nav_list.vue';
import foot from '@/pages/index/foot.vue';
import task from '@/components/task.vue';
import quk_in from '@/components/quk_in.vue';
import stat from '@/utils/stat'
console.log(stat)

export default {
  name: "qk_form",
  props: {
    title: '美国驾照 US driver',
    use_type: '',
    req: {
      default: ['fn', 'ln',   'bor', 'state', 'ads1'],
      type: Array
    },
    field: [],
  },
  data() {
    return {
      user: {},
      use_type: '',
      put: {
        mk_back:false,
        fn: '',
        ln: '',
        name: '',
        idcard: '',
        bor: '',
        sex: 'M',
        COD: '',
        PDN: '',
        RT: '',
        iis: '',
        ssn: '',
        ssn2: '',
        light: 'y',
        state: '',
        ads1: '',
        ads2: '',
        ads3: '',
        rate: 'n',
        get_conf_state: 'USAID',
      },
      options: stat.stat,
      value: '',
      mkphoto: -1,
      log: [],
      out: '',
      activeName: 'line',
      line: '',

    }
  },
  methods: {
    isChinese(text) {
      var re = /[\u4e00-\u9fa5]/;
      return text.match(re);
    },
    async checkname() {
      if (!this.put.fn) {
        return false
      }
      await this.get(`checkname/${this.put.fn}`).then((e) => {
        if (e.sex == 'Male') {
          this.put.sex = 'M'
        } else if (e.sex == 'Female') {
          this.put.sex = 'F'
        } else {
          // this.$message({
          //   type: 'success',
          //   message: '性别获取失败'
          // });
        }
      });
    },
    change_zhou( ){

      this.use_type = 'us_dl_' + this.put.state.toLowerCase()
      console.log(this.use_type)
    },
    async qk(out, batch = false, tm = '') {
      if (!batch) {
        for (let k in this.put) {
          if (k in out) {
            this.put[k] = out[k]
          }

        }
          if (this.put.ads2) {
          this.put.ads1 += '|' + this.put.ads2
          this.put.ads2 = ''
        }
        await this.checkname()
      } else {
        this.put.batch_sn = tm

        for (const ot of out) {
          for (let k in this.put) {
            if (k in ot) {
              this.put[k] = ot[k]
            }
          }
          if (this.put.ads2) {
            this.put.ads1 += '|' + this.put.ads2
            this.put.ads2 = ''
          }
          await this.checkname()
          await this.mk()
        }

        this.get("batch_start", { batch_sn: this.put.batch_sn }, 'post').then((e) => {
          this.$message({
            message: '批量任务已开始',
          })
        })


        this.put.batch_sn = ''

      }
      this.change_zhou()


    },
    isValidDate(date) {
      var regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
      return regex.test(date);
    },
    async mk() {
      this.out = ''
      let p = ''
      if (!this.isValidDate(this.put.bor)) {
        this.$message({
          message: '生日格式错误',
        })
        return
      }
      for (let k in this.put) {
        if (this.req.indexOf(k) !== -1 && !this.put[k]) {
          this.$message({
            message: '请完善资料',
          })
          return
        }
        p += `${k}::${this.put[k]}||`
      }


      if (this.use_type == 'taiwanpassport' || this.use_type == 'hkid') {
        if (!this.isChinese(this.put.fn) || !this.isChinese(this.put.ln)) {
          this.$message({
            message: '姓名需要填写中文',
          })
          return
        }
      }


      await this.get("make_plus", { info: p, put: this.put, type: this.use_type }, 'post').then((e) => {
        this.user.money = e.money
        this.$store.commit('set_money', parseInt(e.money))
        // this.$refs.task.f5()
        this.$message({
          message: '已提交数据,最晚10秒会显示在右侧任务记录',
        })

      });
    },
    f5() {
      this.get("user").then((e) => {

        this.user = e.user
        this.mkphoto = e.mkphoto
        this.log = e.log
      });
    },

  },
  mounted() {
    this.f5()
  },
  components: {  //注册
    nav_list, foot,
    quk_in, task
  }

};
</script>

<style scoped>
#app {
    width: auto !important;
}
.el-input__inner {
  tab-index: 1;
}
</style>
<style  lang="less" scoped>
.form {
  width: 90%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  font-size: 13px;
 

  .tit1 {
    font-size: 18px;
    padding: 10px 0;
    color: #235dd1;
    width: 90%;
    margin-bottom: 10px;
  }

  .put {
    flex: 0 0 100%;


  }

  .rrrrrr {
    line-height: 40px;
    width: 90%;
    margin-bottom: 20px;

    p {
      line-height: 20px;
      color: #696969;
    }
  }

  .row {
    display: flex;
    align-items: center;
    min-height: 50px;
    margin-bottom: 20px;

    .lab {
      flex: 0 0 30%;
    }

    .c {
      flex: 0 0 40%;
    }
  }

  .log {
    flex: 0 0 50%;

    .list {
      width: 90%;

      .itm {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.line_box {
  height: 600px;
  width: 80%;

  p {
    color: #696969;
  }
}

.headss {
  display: flex;
  flex-wrap: wrap;
  .headr {
    flex: 0  0 13%;
    cursor: pointer;
    border: 2px solid #fff;
    margin: 1px;
    img {
      width: 80%;
      margin: auto;
      display: block;
    }
    .headnull {
       width: 80%;
    
      text-align: center;
      font-size: 12px;
      margin: 10px auto;
    }
  }

  .act_head {
    border: 2px solid  #F56C6C ;
  }
}
</style>

