<template>
  <div class="log">
    <div class="tit1">任务记录</div>

    <div class="list">
      <div class="roror" v-for="(vo, i) in log.data" :key="i">
        <div class="innr">
          <div class="ltlt">
            {{ vo.info.ln }}
            {{ vo.info.fn }}
          </div>

          <div class="rtrt">
           
             <el-tag>{{ vo.type_str }} </el-tag>
          </div>
        </div>

        <div class="innr">
          <div class="ltlt">
            
          </div>
          <div class="rtrt">
            <div v-if="vo.status == 2">
<a :href="vo.url" target="__blank" >
              <el-button type="success" size="mini" v-if="vo.url">
                <span v-if="vo.is_task == 3"> 下载</span>
                <span v-else> 查看 </span>
              </el-button>
            </a>
            <a
              v-if="vo.is_task != 3 && vo.url2 && vo.info.mk_back"
              :href="vo.url2"
              target="__blank"
            >
              <el-button type="success" size="mini">
                <span> 查看背面 </span>
              </el-button>
            </a>

             <el-button v-if = "0" type="danger" size="mini" @click="redd(vo.id)"  >
                <span> 重新生成 </span>
              </el-button>
            </div>
            <el-tag v-else >{{ vo.status_str }} </el-tag>
            
          </div>
        </div>

        <div class="innr">
          <div class="txdtrr">
            {{ vo.created_at }}
          </div>
        </div>
      </div>
      <div v-if="0" class="itm">
        <div>
          <el-tag>{{ vo.type_str }} </el-tag>

          <el-tag>{{ vo.created_at }} </el-tag>
        </div>

        <div>
          {{ vo.info.ln }}
          {{ vo.info.fn }}
        </div>

        <div class="cz">
          <a :href="vo.url" target="__blank">
            <el-button type="success" size="mini" v-if="vo.url">
              <span v-if="vo.is_task == 3"> 下载</span>
              <span v-else> 查看 </span>
            </el-button>
          </a>
          <a
            v-if="vo.is_task != 3 && vo.url2 && vo.info.mk_back"
            :href="vo.url2"
            target="__blank"
          >
            <el-button type="success" size="mini">
              <span> 查看背面 </span>
            </el-button>
          </a>
          <el-popconfirm title="确定删除？" @confirm="del(vo.id)">
            <el-button
              slot="reference"
              type="danger"
              size="mini"
              v-if="vo.status == 2"
              >删除</el-button
            >
          </el-popconfirm>
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="log.current_page"
      :page-size="log.per_page"
      :total="log.total"
      @current-change="change_page"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      log: [],
      mode: "a",
      article_page: 1,
      note: "",
      msg: {
        id: 0,
      },
      tm: "",
    };
  },

  mounted() {
    this.f5();
    this.tm = setInterval(this.f5, 10000);
  },
  beforeDestroy() {
    if (this.tm) {
      clearInterval(this.tm);
    }
  },

  methods: {
    change_page(e) {
      this.article_page = e;
      this.f5();
    },
    f5() {
      this.get("/task_log2", { page: this.article_page }, "get", false).then(
        (e) => {
          this.log = e.list;
          this.note = e.note;
          if (e.msg) {
            this.msg = e.msg;
          }
        }
      );
    },
    redd(id) {
      this.get("/re_task/"+id ).then((e) => {
        this.$message({
          type: "success",
          message: "提交成功",
        });
        this.f5();
      });
    },
    del(id) {
      this.get("/del_task", { id: id }, "get", false).then((e) => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.f5();
      });
    },
    one_msg() {
      this.get("/one_msg", {}, "get").then((e) => {});
    },
  },
};
</script>

<style scoped lang="less">
.tit1 {
  font-size: 20px;
  padding: 10px 10px; 
  width: 90%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.log {
  flex: 0 0 100%;

  .list {
    width: 90%;

    .roror {
      margin-top: 10px;

      white-space: nowrap;
      padding: 10px;
      box-shadow: 4px 4px 4px #eee;
    }
    .innr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0;
      .txdtrr {
        width: 100%;
        text-align: right;
        font-size: 12px;
      }
    }
  }
}

.msg-one {
  background: #f56c6c;
  color: #fff;
  padding: 20px 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 87%;
  border-radius: 5px;
}

.msg_ok {
  background-color: #266106;
}
</style>
